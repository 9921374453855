import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import Menu from "@/views/menu/Menu";
import Reserve from "@/views/reserve/Reserve";
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../public/assets/css/style.css';
import {cookieManager} from "@/service/cookieManager";
import {userRepository} from "@/service/repository/userRepository";
import {reservationRepository} from "@/service/repository/reservationRepository";
import {allergenRepository} from "@/service/repository/allergenRepository";
import {cartSessionRepository} from "@/service/repository/cartSessionRepository";
import {venueRepository} from "@/service/repository/venueRepository";
import {menuRepository} from "@/service/repository/menuRepository";
import {notificationRepository} from "@/service/repository/notificationRepository";
import {speakService} from "@/service/speakService";
import Copilot from "@/views/copilot/Copilot";
import {copilotRepository} from "@/service/repository/copilotRepository";
import {cameraService} from "@/service/cameraService";
import {dateFormatterService} from "@/service/dateFormatterService";
import Login from "@/views/login/Login";
import IframeReserve from "@/views/iframeReserve/IframeReserve";
import {faviconService} from "@/service/faviconService";
import WidgetReserve from "@/views/widgetReserve/WidgetReserve";
// import Ebitdata from "@/views/ebitdata/Ebitdata";
import Kiosk from "@/views/kiosk/Kiosk";
import {Capacitor} from "@capacitor/core";
import {listenService} from "@/service/listenService";
import {harvisService} from "@/service/harvisService";
import HomePage from "@/views/homePage/HomePage";
//import VueScrollTo from "vue-scrollto";
import {productOrderRepository} from "@/service/repository/productOrderRepository";
import {walkingService} from "@/service/walkingService";
import {changeLocale, i18n} from "@/i18n";
import {translationRepository} from "@/service/repository/translationRepository";
/*if (window.location.pathname.startsWith('/copilot')) {
	import('./registerServiceWorker');
}*/

const routes = [
	{
		path: '/',
		component: Reserve,
		name: 'Root',
		meta: {
			title: 'Reservas'
		}
	},
	{
		path: '/menu',
		component: Menu,
		name: 'Menu',
		meta: {
			title: 'Menú'
		}
	},
	{
		path: '/reservar',
		component: Reserve,
		name: 'Reserve',
		meta: {
			title: 'Reservas'
		}
	},
	{
		path: '/iframe-reservar',
		component: IframeReserve,
		name: 'IframeReserve'
	},
	{
		path: '/copilot',
		component: Copilot,
		name: 'Copilot',
		meta: {
			title: 'WECOPILOT'
		}
	},
	{
		path: '/login',
		component: Login,
		name: 'Login',
		meta: {
			title: 'Login'
		}
	},
	{
		path: '/widget',
		component: WidgetReserve,
		name: 'Widget',
		meta: {
			title: 'Widget Reservar'
		}
	},
	// {
	// 	path: '/ebitdata',
	// 	component: Ebitdata,
	// 	name: 'Ebitdata',
	// 	meta: {
	// 		title: 'EBITDATA'
	// 	}
	// },
	{
		path: '/kiosk',
		component: Kiosk,
		name: 'Kiosk',
		meta: {
			title: 'Kiosk'
		}
	},
	{
		path: '/home',
		component: HomePage,
		name: 'Home',
		meta: {
			title: 'Home'
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

const loadUser = async (uid = null)=> {
	if (uid){
		console.log("Detectado UID: " + uid);
		await store.dispatch('user/find', uid)
	} else {
		const token = cookieManager.getToken();
		if (token){
			console.log("No hay UID, usando token: ")
			await store.dispatch('user/loginWithToken', token)
		}
		return store.getters['user/user'];
	}
}

async function initializeApp() {
	await store.dispatch('config/showLoadingScreen');
	const websocketUrl = store.getters['config/websocketUrl'];
	const venueId = store.getters['venue/venueId'];

	if(!websocketUrl || !venueId){
		await store.dispatch('config/loadSystemConfig');
		await store.dispatch('venue/loadVenueConfig');
		console.log("la url del socket es ", store.getters['config/websocketUrl'])
	}

	let initialLocale = 'es';
	let localeChange = false;

	const cookieSelectedLocale = cookieManager.getCookie('selectedLocale');
	if(cookieSelectedLocale){
		localeChange = initialLocale !== cookieSelectedLocale;
		initialLocale = cookieSelectedLocale;
	}
	await changeLocale(i18n, 'es');

	if (localeChange){
		await changeLocale(i18n, initialLocale);
	}
}


router.beforeEach(async (to, from, next) => {
	let user = null;
	let resultRoleCopilot = null;
	await initializeApp();

	const uid = to.query.uid;
	console.log("Cargando ruta: "+ to.name)

	faviconService.changeFaviconByRoute(to.path);

	document.title = `${to.meta.title} | ${store.getters['venue/businessName']}` || 'WeWelcom';
	//next();

	switch (to.name){
		case "Root":
			if (Capacitor.isNativePlatform()){
				next('/copilot');
			} else {
				next('/reservar');
			}
			break;
		case "Menu":
			console.log('Menu desde el switch');
			user = await loadUser(uid);
			await store.dispatch('menu/loadMenus');
			break;
		case "Reserve":
			console.log('Reservas desde el switch');
			store.commit('reservationForm/setIsIframe', {isIframe: false})
			await store.dispatch('reservationForm/setAvailableRooms');
			break;
		case "IframeReserve":
			console.log('IframeReserve desde el switch');
			store.commit('reservationForm/setIsIframe', {isIframe: true})
			await store.dispatch('reservationForm/setAvailableRooms');
			break;
		case "Copilot":{
			console.log('Copilot desde el switch');
			user = await loadUser(uid);
			resultRoleCopilot = await userRepository.checkRoleCopilot();
			if(!user || resultRoleCopilot === 401 || resultRoleCopilot === 403){
				next('/login');
			}
			const cookieSelectedRole = cookieManager.getCookie('selectedRole');
			if(cookieSelectedRole){
				store.commit('copilot/setSelectedRole', {selectedRole: cookieSelectedRole})
			}
			await store.dispatch('reservationForm/setAvailableRooms');
			await store.dispatch('copilot/initAvailableRoles', {user});
			await store.dispatch('copilot/getTables');
			await store.dispatch('menu/loadMenus');
			await store.dispatch('copilot/updateReservations');
			await store.dispatch('copilot/updateOrdersAndNotifications');

			break;
		}

		case "Login":
			console.log('Login desde el switch');
			break;
		case "Widget":
			console.log('widget desde el switch');
			break;
		case "Ebitdata":
			console.log('Ebitdata desde el switch');
			await store.dispatch('ebitdata/getTables');

			user = await loadUser(uid);
			resultRoleCopilot = await userRepository.checkRoleAdmin();
			if(!user || resultRoleCopilot === 401 || resultRoleCopilot === 403){
				next('/login');
			}
			break;
		case 'Kiosk':
			user = await loadUser(uid);
			resultRoleCopilot = await userRepository.checkRoleCopilot();
			if(!user || resultRoleCopilot === 401 || resultRoleCopilot === 403){
				next('/login');
			}
			console.log('Kiosk desde el switch');
			break;
		case 'Home':
			console.log('Home desde el switch');
			if (store.getters['venue/resourceId'] === "elcapirotedegranada"){
				faviconService.setFavicon(`${store.getters['config/weWelcomUrl']}/world/img/${store.getters['venue/resourceId']}/favicon/favicon.ico`)
			}
			break;
		default:
			next('/reservar');
			break;
	}

	next();
});

router.afterEach(async () => {
	await store.dispatch('config/hideLoadingScreen');
})

const app = createApp(App);
app.use(router);
app.use(store);
//app.use(VueScrollTo)
app.use(i18n);
app.provide('cookieManager', cookieManager);
app.provide('dateFormatterService', dateFormatterService);
app.provide('speakService', speakService);
app.provide('listenService', listenService);
app.provide('harvisService', harvisService);
app.provide('cameraService', cameraService);
app.provide('notificationRepository', notificationRepository);
app.provide('userRepository', userRepository);
app.provide('copilotRepository', copilotRepository);
app.provide('reservationRepository', reservationRepository);
app.provide('allergenRepository', allergenRepository);
app.provide('cartSessionRepository', cartSessionRepository);
app.provide('venueRepository', venueRepository);
app.provide('translationRepository', translationRepository);
app.provide('menuRepository', menuRepository);
app.provide('productOrderRepository', productOrderRepository);
app.provide('walkingService', walkingService);
app.mount('#app');
