<template>
	<!-- Modal qr -->
	<div v-if="reservation" class="modal fade" :id="'modalQr' + reservation.id" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" id="exampleModalLabel">
						<i class="fas fa-qrcode"></i> Invitación
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-6">
							<div v-if="reservation.name"><b>Reserva de:</b> {{ reservation.name }}</div>
						</div>
						<div class="col-6">
							<div v-if="reservation.table"><b>Mesa:</b> {{ reservation.table }}</div>
						</div>
					</div>

					<div id="'modalQr' + reservation.id" class="content-center">
						<GeneratorQr :link="getLinkShareMenuQr(reservation)" :id="reservation.id" />
					</div>

					<p class="text-center text-md-start">Pide al cliente escanear este QR para ver el menú.</p>

					<!--				<p>Lista de invitados</p>-->

					<!--				<div v-if="reservation.reservationUsers.some(diner => diner.type !== 1)" class="reservation-guests">-->
					<!--					<i class="fas fa-users me-1"></i>-->
					<!--					<span v-for="(diner, index) in reservation.reservationUsers" :key="diner.userId">-->
					<!--							{{ diner.type !== 1 ? diner.name + (index !== reservation.reservationUsers.length - 1 ? ',' : '.') : '' }}-->
					<!--						</span>-->
					<!--				</div>-->
					<!--				<div class="text-center text-md-start" v-else>-->
					<!--					No hay invitados-->
					<!--				</div>-->
				</div>
				<div class="modal-footer justify-content-between">
					<button v-if="isReservationStatusStart && !isReservationCancelled" class="btn btn-light" @click.stop="openCommanderMenu" type="button">
						Comandero
					</button>

					<DismissButton/>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import * as bootstrap from "bootstrap";

import {hideAllModals} from "@/utils";

import GeneratorQr from "@/components/generatorQr/GeneratorQr";
import DismissButton from "@/core/DismissButton";

export default {
	name: "CopilotModalQrReservation",
	data() {
		return{

		}
	},
	components:{
		GeneratorQr,
		DismissButton

	},
	computed:{
		...mapGetters('config', ['wePassUrl']),
		...mapGetters('copilot',['selectedReservation']),
		reservation(){
			return this.selectedReservation
		},

		isReservationStatusStart() {
			return this.reservation.menuAccessEnabled === 1;
		},
		isReservationCancelled() {
			return this.reservation.status === -1;
		},
	},
	mounted() {

	},
	methods: {
		getLinkShareMenuQr(reservation) {
			return this.wePassUrl + `/invitacion?wid=${reservation.id}&menu=1`;
		},

		openCommanderMenu(event) {
			event.stopPropagation();
			hideAllModals();
			const collapseElement = document.getElementById('offcanvasCopilotCommander');
			if (collapseElement) {
				const collapseInstance = new bootstrap.Offcanvas(collapseElement, {
					toggle: false
				});
				collapseInstance.show();
			}
			console.log('Vamos a llamar a setear el commanderReservation le pasamos', this.reservation);
			store.commit('copilot/setCommanderReservation', {commanderReservation: this.reservation});
		},
	}
}
</script>
