<template>
	<label :class="{ heartbeat: hasUnitsChanged }" class="product-card-client product-card-client-list list-copilot" :for="`option_checkbox_${order.name}-${order.productOrderId}`">
		<div class="product-card-body">
			<img v-if="showPictureMenu !== null && imageUrl()" class="product-card-img " :src="imageUrl()" @error="handleImageError" alt="...">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">
						<span v-if="order.quantity && source !== 'account' && !order.units">{{ order.quantity }}</span>
						<span v-if="source === 'account'">x{{ order.quantity }}</span>
						<span v-if="order.units > 1">{{ order.units }} (uds.)</span>
						<span v-if="order.count && order.count > 1" >{{ order.count }}</span>
						{{ order.shortName || order.name }}
						<span v-if="source === 'account'">{{ order.price }}</span>
					</div>
				</div>
				<div class="ingredients">
					<div v-for="(choiceGroup, index) in order.choices" :key="index">
						<span>- {{ choiceGroup.choice }}</span>
					</div>
				</div>
			</div>

			<div class="product-card-btns flex-column">
				<div class="fw-bold badge badge-secondary" v-if="order.dishClassHasChanged">Como {{order.dishClass.name.toLowerCase()}}</div>
				<div class="fw-bold badge badge-secondary" v-if="order.shareable">Para Compartir</div>
				<span v-if="productStatusLoss" class="badge badge-danger">Merma</span>
				<span v-if="productStatusReject" class="badge badge-danger">Quitado</span>

				<input v-if="showCheckbox" class="form-check-input" type="checkbox" :checked="isChecked(order.productOrderId)" :id="`option_checkbox_${order.name}-${order.productOrderId}`" :value="order.productOrderId" @change="handleCheckboxChange(order.productOrderId, $event)">

				<select v-if="showSelectAvailability" v-model="availability" :class="selectClass" class="form-select" style="width: 10rem; height: 2rem;" aria-label="Default select example">
					<option selected value="availability">Disponible</option>
					<option value="not-availability">No Disponible</option>
				</select>

				<!--				<button v-if="source === 'orders' && order.dishClass && order.dishClassHasChanged" class="btn btn-outline-primary" @click="handleCheckboxDishClass">-->
				<!--					{{ activeCheckboxDishClass ? 'Servir como segundo' : 'Servir como primero' }}-->
				<!--				</button>-->
			</div>
		</div>
	</label>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {printChoices, showModal} from "@/utils";
import store from "@/store";

export default {
	name: 'OrdersProductCard',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
	},
	emits: ['add-unit', 'subtract-unit'],
	props: {
		order: {
			type: Object,
			required: true,
			default: null
		},
		source: {
			type: String,
			default: null
		},

		reservation: {
			type: Object,
			default: null
		},

	},
	data() {
		return {
			showPictureMenu: null,
			availability: 'availability',
			hasUnitsChanged: false,
			idModalMoveProductOrder: 'modal-confirm-dish-class-changed-' + this.order.name + '-' + this.order.productOrderId,
		};
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue']),
		...mapGetters('copilot', ['selectedProductOrdersToServe', 'selectedProductOrdersToPrepared','productOrder']),
		chosenOptions() {
			const counts = this.order.choices.reduce((acc, option) => {
				acc[option.choice] = (acc[option.choice] || 0) + 1;
				return acc;
			}, {});

			return Object.keys(counts).map(choice => ({
				choice,
				count: counts[choice]
			}));
		},
		productImg() {
			return `${this.urlVenue}/world/img/${this.resourceId}/product/${this.order.pictureMenu}`;
		},
		productFoodImg() {
			return `${this.urlVenue}/world/img/${this.resourceId}/product/${this.order.pictureMenu}`;
		},
		showCheckbox() {
			return this.source === 'readyOrder' || this.source === 'hybridOffcanvas'
		},
		showSelectAvailability() {
			return this.source === 'availability'
		},
		isChecked() {
			return (orderId) => {
				return this.selectedProductOrdersToServe.includes(orderId) || this.selectedProductOrdersToPrepared.includes(orderId);
			};
		},
		selectClass() {
			return this.availability === 'availability' ? 'availability' : 'not-availability';
		},
		activeCheckboxDishClass(){
			return this.order.dishClassHasChanged
		},

		orderPreparingInKitchen(){
			return this.order.statusProductOrder === 2
		},

		orderWaitingForWaiter(){
			return  this.order.statusProductOrder === 3
		},

		orderAlreadyOnTable() {
			return 	this.order.statusProductOrder === 100
		},

		productStatusReject(){
			return this.order.statusProductOrder === -1
		},

		productStatusLoss(){
			return this.order.statusProductOrder === -3
		},
	},
	watch: {
		order: {
			handler(newValue, oldValue) {
				if(newValue.count !== oldValue.count){
					this.hasUnitsChanged = true;
				}
			},
			deep: true
		}
	},
	beforeMount() {
		store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: []});
		store.commit('copilot/setSelectedProductOrdersToPrepared', {selectedProductOrdersToPrepared: []});
	},
	mounted() {
		this.checkImage();
	},
	methods: {
		...mapActions('copilot', ['getIdReservation','addProductOrder']),
		imageUrl() {
			return this.productImg;
		},
		checkImage() {
			const img = new Image();
			img.src = this.productImg;

			img.onerror = () => {
				this.showPictureMenu = null;
			};

			this.showPictureMenu = true
		},
		handleImageError() {
			this.showPictureMenu = null;
		},
		printChoices(choices) {
			return printChoices(choices)
		},
		handleClickProduct() {
			if (!this.source) {
				return
			}
			console.log(this.order.name)
		},
		handleCheckboxChange(productOrderId, event) {
			let selectedProductOrdersLocalArray = []

			if (this.source === 'readyOrder') {
				selectedProductOrdersLocalArray = [...this.selectedProductOrdersToServe];
			} else if (this.source === 'hybridOffcanvas') {
				selectedProductOrdersLocalArray = [...this.selectedProductOrdersToPrepared];
			}
			// Si el checkbox está marcado, añadimos el productOrderId al array

			if (event.target.checked) {
				if (!selectedProductOrdersLocalArray.includes(productOrderId)) {
					selectedProductOrdersLocalArray.push(productOrderId);
				}
			} else {
				selectedProductOrdersLocalArray = selectedProductOrdersLocalArray.filter(prodOrdId => prodOrdId !== productOrderId);
			}
			if (this.source === 'readyOrder') {
				store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: selectedProductOrdersLocalArray});
				console.log('despues del handle', selectedProductOrdersLocalArray)
			} else if (this.source === 'hybridOffcanvas') {
				store.commit('copilot/setSelectedProductOrdersToPrepared', {selectedProductOrdersToPrepared: selectedProductOrdersLocalArray});
				console.log('despues del handle hybridOffcanvas', selectedProductOrdersLocalArray)
			}

		},
		async handleCheckboxDishClass() {
			await this.addProductOrder({productOrder: this.order});
			showModal(this.idModalMoveProductOrder);
		},

	}
}
</script>

<style>
.heartbeat {
	animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.02);
	}
	50% {
		transform: scale(1.04);
	}
	75% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}
</style>
