<div id="welcom-copilot">

	<!--  Navbar  -->
	<nav class="navbar navbar-custom">
		<div class="container-md">
			<img src="https://dev.wewelcom.io/world/img/wewelcom/logo/logo.png" alt="" class="logo">

			<button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="offcanvas offcanvas-end offcanvas-primary" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
				<div class="offcanvas-header">
					<h5 class="offcanvas-title" id="offcanvasNavbarLabel">
							<span class="user ">
								<i class="fas fa-user-circle"></i> {{ user.fullName }}<br>
								<span class="user-role" v-if="false">
									<span v-for="{rol,i} in user.copilotRolesActiveTest" :key="i">{{ rol }}</span>
								</span>
							</span>

						<span v-if="false">
								<button class="btn btn-secondary btn-sm">
									<i class="fas fa-sign-out-alt"></i>
								</button>
							</span>
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
				</div>
				<div class="offcanvas-body offcanvas-justify-content">
					<div>
						<select class="form-select mb-3" v-model="selectedRoleLocal">
							<option value="0" selected>{{t('copilot.allRoles')}}</option>
							<option v-for="role in availableRoles" :key="role.id" :value="role.id">
								{{ role.name }}
							</option>
						</select>

						<ul class="navbar-nav justify-content-end flex-grow-1 gap-3">
							<li class="nav-item">
								<div class="row align-items-end">
									<div class="col-7">
										{{ t('copilot.selectLanguage') }}:
									</div>
									<div v-if="availableLocales.length > 1" class="col-5">
										<select
											id="language-select"
											class="form-select w-100"
											v-model="selectedLocale"
											@change="handleChangeLocale($event.target.value)"
										>
											<option
												v-for="locale in availableLocales"
												:key="locale.code"
												:value="locale.code"
											>
												{{ locale.name }}
											</option>
										</select>
									</div>
								</div>
							</li>

							<li v-if="menuProducts.length > 0" class="nav-item">
								<a class="nav-link active" data-bs-toggle="offcanvas" href="#offcanvas-menu-edit" role="button" aria-controls="offcanvas-menu-edit">
									{{ t('copilot.editarMenusDelDia')}}
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link active" data-bs-toggle="offcanvas" href="#offcanvas-products-edit-status" role="button" aria-controls="offcanvas-menu-edit">
									{{ t('copilot.disponibilidadDePlatos')}}
								</a>
							</li>
							<li v-if="!(view === 'Barra' || view === 'Cocina')" class="nav-item">
								<span data-bs-toggle="collapse" href="#collapseNotificationsTime" role="button" aria-expanded="false" aria-controls="collapseExample">
									{{ t('copilot.intervaloDeNotificaciones')}}
								</span>

								<div class="collapse py-3" id="collapseNotificationsTime">
									<div class="form-check form-check-inline">
										<input class="form-check-input" name="notificationsMode" id="modeNotification0" type="radio" value="0" v-model="notificationModeLocal" checked/>
										<label class="form-check-label" for="modeNotification0"> Tiempo real </label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" name="notificationsMode" id="modeNotification1" type="radio" value="0.25" v-model="notificationModeLocal"/>
										<label class="form-check-label" for="modeNotification1"> 15s </label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" name="notificationsMode" id="modeNotification2" type="radio" value="0.5" v-model="notificationModeLocal"/>
										<label class="form-check-label" for="modeNotification2"> 30s </label>
									</div>
								</div>
							</li>
						</ul>
					</div>

					<div class="  text-center">
						<span class="" style="font-size:.7rem;">Versión: 0.2410.4</span>
					</div>
				</div>
			</div>
		</div>
	</nav>

	<div class="my-4 py-2"></div>

	<CopilotContainerKitchen v-if="view === 'Barra' || view === 'Cocina'"/>
	<CopilotMainComponent v-else/>
	<!--  Footer	-->
	<footer-welcom/>

	<div v-if="view === 'Barra' || view === 'Cocina'" class="my-4 p-3"></div>

	<div v-if="view !== 'Barra' || view !== 'Cocina'" class="my-4 py-2 hidden-md"></div>

	<!--  Offcanvas notificaciones	-->
	<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" id="offcanvasNotifications">Notificaciones</div>
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body p-3">
			<div class="mb-3">
				<div class="h4">Última notificación</div>
				<div v-if="latestNotification != null" class="notification-copilot">
					<div class="notification-copilot-header">
						<div>
							<i class="fas fa-clock"></i> {{ formatNotificationDate(latestNotification) }}
						</div>
						<button class="btn btn-primary btn-circle" @click="replayNotification(latestNotification)">
							<i class="fas fa-play"></i>
						</button>
					</div>
					<div class="notification-copilot-body">
						<div>{{ latestNotification.message }}</div>
					</div>
				</div>
				<div v-else class="notification-copilot">
					<div class="notification-copilot-body">
						<div>No hay notificaciones actualmente</div>
					</div>
				</div>
			</div>

			<div>
				<div class="h4">Historial de notificaciones</div>
				<div v-if="notificationHistory.length" class="row row-column-gap">
					<div class="col-12" v-for="notification in notificationHistory" :key="notification.id">
						<div class="notification-copilot" :id="notification.id">
							<div class="notification-copilot-header">
								<div>
									<i class="fas fa-clock"></i> {{ dateFormatterService.formattedTime(notification.dateCreated) }}
								</div>
								<button class="btn btn-primary btn-rounded" :class="{ 'active': isNotificationPlaying }" @click="replayNotification(notification)">
									<i v-if="isNotificationPlaying" class="fas fa-play"></i>
									<i v-else class="fas fa-play"></i>
								</button>
							</div>
							<div class="notification-copilot-body">
								<div>{{ notification.message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="notification-copilot">
					<div class="notification-copilot-body">
						<div>No hay Historial de notificaciones</div>
					</div>
				</div>
			</div>
		</div>
		<div class="offcanvas-footer">
			<DismissButton :is-modal="false"/>
		</div>
	</div>
	<!--  Offcanvas disponibilidad productos	-->
	<CopilotProductAvailabilityManager />
</div>

<check-copilot-modal :ws-client="webSocketClient"></check-copilot-modal>

<!--  Offcanvas Menu editable	-->
<copilot-daily-menu></copilot-daily-menu>

