<div id="welcom-menu" :data-bs-theme="resourceId" :class="{'bg-white':isCopilotRoute}">

	<!--  Header component	-->
	<HeaderComponent :class="{'pt-0':!isValidReservation && !isCopilotRoute}" v-if="!isCopilotRoute" :url-restaurant="weWelcomUrl" :text-title="t('menu.laCarta')" :subTitle="businessName" :image-restaurant="resourceId"/>

	<div v-if="activeMenu != null && isLoad">
		<!--  Informacion de la reserva: Mesa-->
		<div v-if="!isCopilotRoute" class="navbar navbar-top navbar-client" style="padding: 0.50rem">
			<div class="container-lg">
				<!--				<div v-if="reservationInfo">Mesa {{ reservationInfo.table }}</div>-->
				<button v-if="isValidReservation" class="btn btn-transparent" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-bag-orders" aria-controls="offcanvas-bag-orders">
					<i class="fa-solid fa-clock-rotate-left"></i>
					{{ t('menu.historialPedidos')}}
				</button>
				<div></div>

				<!-- Example single danger button -->
				<div v-if="availableLocales.length > 1" class="btn-group">
					<button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
						{{ getLanguageName(selectedLocale) }}
					</button>
					<ul class="dropdown-menu">
						<li v-for="locale in availableLocales" :key="locale">
							<button class="dropdown-item" :class="{'active': locale.code === selectedLocale}" @click="handleChangeLocale(locale.code)">
								{{ locale.name  }}
							</button>
						</li>
					</ul>
				</div>

			</div>
		</div>
		<!--  Informacion de la reserva: Nombre e Invitados-->
		<div class="w-100" v-if="!isCopilotRoute && isValidReservation">
			<div class="info-inblock info-inblock-black">
				<div v-if="user && user.name">{{ t('menu.saludo', { name: user.name}) }}</div>
			</div>
			<div class="info-inblock info-inblock-black fs-6 text-white-50">
				<div @click="openModalNewUserName" style="font-size: .75rem" v-if="user && user.name">{{ t('menu.areYouNot', { name: user.name}) }} <i class="fa-solid fa-circle-question"></i></div>
			</div>
			<div v-if="false" class="info-inblock info-inblock-black">
				<div id="guests" v-if="isCreator && invitedUsers.length || !isCreator && invitedUsers.length">
							<span v-for="(invited, index) in invitedUsers" :key="invited.id">
								<span v-if="invited.userId === user.id">{{ t('menu.you') }}</span>
								<span v-if="invited.userId !== user.id">{{ invited.name }}</span>
								<span v-if="index !== invitedUsers.length - 1">, </span>
								<span v-if="index === invitedUsers.length - 1">.</span>
							</span>
				</div>
				<span v-else>
							{{ t('menu.noConfirmedInvitees') }}
						</span>
			</div>
		</div>

		<!--	Seccion de compartir	-->
		<section v-if="isValidReservation && !isCopilotRoute" class="section-primary section-not-rounded">
			<div class="section-header">
				<div class="section-title">{{ t('menu.comparteTuCarta') }}</div>
			</div>
			<div class="section-content">
				<div class="row">
					<div class="col-6">
						<button data-bs-toggle="modal" data-bs-target="#modalShareQR" class="btn btn-secondary w-100">QR</button>
					</div>
					<div class="col-6">
						<a :href="linkShareMenuWhatsapp" target="_blank" class="btn btn-secondary w-100">Whatsapp</a>
					</div>
				</div>
			</div>
		</section>

		<NavbarScrollCategories
			:active-menu="filteredMenus"
			:menus="menus"
			:product-unit="productsCart"
			:is-copilot-route="isCopilotRoute"
			:reservation-promotion="reservationInfo.promotion"
			@subtract-unit="subtractUnit"
			@add-product="addProductToCart"
			@add-unit="addUnit"
			:is-Valid-Reservation="isValidReservation"
		/>

		<!-- Recomendaciones del chef   -->
		<div v-if="!isCopilotRoute && recommendedProducts.length > 0 && !isOpenModalSearch" class="chef-recommendations">
			<div class="card-secction mb-0">
				<hr>
				{{ t('menu.recomendacionesDelChef') }}
				<hr>
			</div>

			<div class="position-relative">
				<button class="scroll-arrow-welcom left-arrow" v-if="showLeftArrow" @click="scrollLeft">
					<span class="btn btn-secondary btn-circle"><i class="fas fa-chevron-left"></i></span>
				</button>
				<div ref="scrollContainer" class="scroll-x position-relative" @scroll="updateArrows">
					<menu-product-card
						v-for="product in recommendedProducts"
						:key="product.sku"
						:is-copilot-route="isCopilotRoute"
						:reservation-promotion="reservationInfo.promotion"
						:is-Valid-Reservation="isValidReservation"
						@add-product="addProductToCart"
						:product="product"
						@subtract-unit="subtractUnit"
						@add-unit="addUnit"
						:product-unit="productsCart"
						:top-choice="true"
					></menu-product-card>
				</div>
				<button class="scroll-arrow-welcom right-arrow" v-if="showRightArrow" @click="scrollRight">
					<span class="btn btn-secondary btn-circle"><i class="fas fa-chevron-right"></i></span>
				</button>
			</div>
		</div>

		<div v-if="filteredMenus">
			<div
				v-for="menu in filteredMenus"
				:key="menu.id"
				:id="menu.id + menu.name"
				:data-section-menu="`${menu.menuTypeName}`"
			>
				<!-- Renderiza las categorías principales del menú -->
				<menu-category
					v-for="category in menu.menuCategories"
					:key="category.id"
					:category="category"
					:is-copilot-route="isCopilotRoute"
					:reservation-info="reservationInfo"
					:is-valid-reservation="isValidReservation"
					@add-product="addProductToCart"
					:products-cart="productsCart"
					@subtract-unit="subtractUnit"
					@add-unit="addUnit"
				></menu-category>
			</div>
		</div>

		<!--Nav-Bottom-->
		<nav-bottom v-if="isLoad" :total-products="totalProducts" :active-menu="activeMenu" :is-valid-reservation="isValidReservation" :cart-session="reservationInfo.cartSession" :is-copilot-route="isCopilotRoute" :menus="menus"></nav-bottom>

	</div>
	<div v-else>
		<section class="text-center d-flex flex-column justify-content-center align-items-center" style="height: 70vh">
			<SpinnerIcon/>
		</section>
	</div>

	<footer-welcom v-if="!isCopilotRoute"></footer-welcom>

	<div class="m-4 p-4"></div>

	<!--Modal Message-->
	<modal-message :message="messageCart"></modal-message>

	<!-- Modal compartir por qr -->
	<div class="modal fade" id="modalShareQR" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title" id="share-menu-qr">
						<i class="fas fa-exclamation-circle"></i> {{ t('menu.shareQrWithYourInvitee') }}</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">

					<div class="content-center">
						<generator-qr v-if="reservationInfo.id && user" :link="linkShareMenuQr" class="content-qr"></generator-qr>
					</div>

					<p class="text-center text-md-start">{{ t('menu.shareQrWithYourInviteeDescription') }}</p>
				</div>
				<div class="modal-footer">
					<DismissButton/>
				</div>
			</div>
		</div>
	</div>

	<!--Modal solicitar cuenta	-->
	<menu-modal-request-account :reservation-info="reservationInfo"></menu-modal-request-account>

	<!--Modal star	-->
	<menuOffcanvasAssessment :reservation-info="reservationInfo" :historic-products="historicUnification"/>

	<MenuModalPaymentResult :reservation-info="reservationInfo"/>
	<!-- Modal del carrito -->
	<div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" id="orders">
		<div class="modal-dialog  modal-fullscreen">
			<div class="modal-content d-flex flex-column">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="exampleModalLabel">{{ t('menu.orderTopText') }}</h1>
					<button type="button" class="btn-close" @click="dismissProductInputs" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div v-if="totalProducts > 0" class="products-list-content overflow-auto" style="flex-grow: 1;">
						<div v-for="(group, dishClassName) in groupedProducts" :key="dishClassName">
							<p class="fw-bold text-uppercase">{{ dishClassName }}</p>
							<menu-cart-product
								v-for="(product,index) in group"
								:key="generateProductKey(product)"
								@subtract-unit="subtractUnit"
								@add-unit="addUnit"
								:product="product"
								:is-copilot-route="isCopilotRoute"
								:is-active-product-inputs="isActiveProductInputs"
								class="mb-2"
							></menu-cart-product>
						</div>
					</div>
				</div>
				<div class="modal-footer flex-column">
					<div class="w-100">
						<button class="btn btn-sm btn-outline-primary w-100 mb-3" @click="activateDishClassInputs" v-if="!isActiveProductInputs">
							{{ t('menu.changeDishClassQuestion') }}</button>

						<div v-if="totalProducts > 0 && weOrderClientComments" class="form-floating">
							<textarea v-model="cartClientMessage" class="form-control" placeholder="¿Algún comentario de tu pedido?" id="comments"></textarea>
							<label for="comments">{{ t('menu.orderCommentQuestion') }}</label>
						</div>
					</div>
					<div class="w-100 d-flex justify-content-end gap-2">
						<DismissButton :on-click="dismissProductInputs"/>
						<button
							v-if="totalProducts > 0"
							class="btn btn-primary"
							@click="handleConfirmCart"
						>
							<span v-html="menuButtonContentOrder"></span>
							<SpinnerIcon v-if="isSpinnerIconLoading"/>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--  Offcanvas BagOrders	-->
	<div id="offcanvas-bag-orders" aria-hidden="true" class="offcanvas offcanvas-bottom fade" tabindex="-1">
		<div class="offcanvas-header mb-0">
			<div id="bag-orders-label" class="offcanvas-title h4">
				<BagOrdersIcon/>
				{{ t('menu.searchHistory') }}
			</div>
			<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
		</div>
		<div class="offcanvas-body p-3">
			<ul class="nav nav-underline mb-3">
				<li class="nav-item">
					<button class="nav-link" :class="{ active: viewMode === 'unified' }" @click.stop="viewMode = 'unified'">Ver todo</button>
				</li>
				<li class="nav-item">
					<button class="nav-link" :class="{ active: viewMode === 'user' }" @click.stop="viewMode = 'user'">Ver por pedidos</button>
				</li>
			</ul>
			<transition name="fade" :duration="300" mode="out-in">
				<div v-if="viewMode === 'user'" key="user">
					<div class="orders-history bg-transparent" v-for="cartSessionOrder in processedCartSessionOrders" :key="cartSessionOrder.cartSessionOrderId">
						<div class="list-name">
							<div>
								<i class="fas fa-utensils"></i>{{ cartSessionOrder.userName }}
							</div>
						</div>
						<div class="extras-products-list">
							<orders-product-card :order="order" v-for="order in  groupedItems(cartSessionOrder)" :key="order.cartSessionOrderId"></orders-product-card>
						</div>
						<!--							<ul>-->
						<!--								<li v-for="item in groupedItems(cartSessionOrder)" :key="item.cartSessionOrderId">-->
						<!--									<div class="list-item">-->
						<!--										<div><b>{{ item.count }}</b></div>-->
						<!--										<div>{{ item.name }}</div>-->
						<!--									</div>-->
						<!--									<div v-html="printChoices(item.choices)"></div>-->
						<!--								</li>-->
						<!--							</ul>-->
					</div>
				</div>
				<div v-else key="unified">
					<div class="extras-products-list">
						<orders-product-card :order="order" v-for="order in historicUnification" :key="order.productSku"></orders-product-card>
					</div>
				</div>
			</transition>
		</div>
		<div class="offcanvas-footer">
			<DismissButton :is-modal="false"/>
		</div>
	</div>
	<modal-privacy></modal-privacy>

	<MenuModalSelectedNameInvitation :reservation-info="reservationInfo" :invited-users="invitedUsers"/>
</div>
