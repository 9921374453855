<template>
	<svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve">
		<line class="st0" x1="4" y1="16" x2="29" y2="16"/>
		<path class="st0" d="M13.3,31l1.9-1.4c3.3-2.4,5.7-5.7,6.8-9.6l0,0h-2.6l0,0.1c-1.1,1.9-2.7,3.6-4.5,4.9l0,0c-0.6,0.4-1.5-0.1-1.4-1
	l0.1-1.7c-0.2-1.3-1.2-2.2-2.3-2.2h-0.6v1c0,3.2-0.9,6.4-2.6,9l0,0"/>
		<polyline class="st0" points="27,16 25,20 8,20 6,16 "/>
		<path class="st0" d="M6,15.5C6,9.7,10.7,5,16.5,5S27,9.7,27,15.5"/>
		<circle class="st0" cx="16.5" cy="3.5" r="1.5"/>
</svg>
</template>

<script>
export default {
	name: 'ServeFoodIcon',
	props: {
		fillColor: String
	}
}
</script>
<style type="text/css">
.st0 {
	fill: #000000;
	stroke: none;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
}
</style>
