import {sendRequestJson} from '@/utils'

class TranslationRepository {
	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('TranslationRepository con urlBase: ', this.urlBase)
	}

	async getTranslations(data) {
		console.log("Llamando al translations desde el repositorio")
		const url = this.urlBase + "/world/api/translations/get"
		return await sendRequestJson({data, url, method:"POST"}, false);
	}

}

export const translationRepository = new TranslationRepository();
