<template src="./login.html"></template>

<script>
import store from "@/store";

import FooterWelcom from "@/core/FooterWelcom";
import HeaderComponent from "@/core/HeaderComponent";
import {mapGetters} from "vuex";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'LoginWelcom',
	inject: {
		userRepository: 'userRepository',
		cookieManager: 'cookieManager'
	},
	data() {
		return {
			signupEmail: null,
			signupPassword: null,
			showErrorMessageForm: false,
			loginButtonContent: 'Login',
			isSpinnerIconLoading: false,

			errorMessage: "",
			isLogged: false,
			roleNameResolver: {"ROLE_COPILOT": "Copilot", "ROLE_KIOSK": "Kiosk"},
			selectedRole: null,
			userRoles:[],

			isPasswordVisible: false,

		};
	},
	components: {
		'footer-welcom': FooterWelcom,
		HeaderComponent,
		SpinnerIcon
	},
	async beforeMount() {

	},
	async mounted() {
		console.log('Cargando login componente')
	},
	watch: {},
	computed: {
		...mapGetters('config',['weWelcomUrl']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName']),

		isDisabledButtonRedirect(){
			return !this.selectedRole
		},
	},
	methods: {
		togglePasswordVisibility() {
			this.isPasswordVisible = !this.isPasswordVisible;
		},

		async submitLogin() {
			event.preventDefault();
			console.log('Logando');
			this.loginButtonContent = 'Accediendo...'
			this.isSpinnerIconLoading = true
			const resultLogin = await this.userRepository.loginCopilot(this.signupEmail, this.signupPassword);

			if (resultLogin.result === -1) {
				this.loginButtonContent = 'Login'
				this.isSpinnerIconLoading = false
				this.errorMessage = 'Correo electrónico o contraseña incorrectos. Por favor, inténtalo de nuevo.'
				return this.showErrorMessageForm = true;
			}

			if (resultLogin.result === -2) {
				this.loginButtonContent = 'Login'
				this.isSpinnerIconLoading = false
				this.errorMessage = 'El usuario no tiene permisos para acceder.'
				return this.showErrorMessageForm = true;
			}

			if (resultLogin.result === 0) {
				store.commit('user/setUser', {user:resultLogin.user});
				this.cookieManager.setToken(resultLogin.token)
				this.userRoles = resultLogin.userRolesVenue.filter(role => ["ROLE_COPILOT", "ROLE_KIOSK"].includes(role));

				if(this.userRoles.includes("ROLE_COPILOT")){
					await this.$router.push(`/copilot`)
					//const route = this.roleNameResolver[this.userRoles[0]].toLowerCase();
					//await this.$router.push(`/${route}`)
				}
				this.loginButtonContent = 'Login'
				this.isSpinnerIconLoading = false
				// this.isLogged = true;
				// this.$router.push('/copilot')

			}
		},

		submitOnRedirect(){
			event.preventDefault();
			console.log(this.roleNameResolver[this.selectedRole].toLowerCase());
			const route = this.roleNameResolver[this.selectedRole].toLowerCase();
			this.$router.push(`/${route}`)
		}

	}
}
</script>
