<template>
	<!-- Button that uses both computed properties for its attributes and a method to handle events -->
	<button type="button" class="btn btn-outline-primary" :data-bs-dismiss="dismissAction" @click="handleClick">
		{{ buttonText }}
	</button>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from 'vue';

export default {
	name: 'DismissButton',
	props: {
		isModal: {
			type: Boolean,
			default: true
		},
		contentBtnText: {
			type: String,
			default: ''
		},
		onClick: {
			type: Function,
			default: null
		}
	},
	setup(props) {
		const { t } = useI18n();

		// If `contentBtnText` prop is empty, use the translated text as the default value.
		const buttonText = computed(() => {
			return props.contentBtnText || t('menu.orderCloseButton');
		});

		const dismissAction = computed(() => {
			return props.isModal ? 'modal' : 'offcanvas';
		});

		const handleClick = () => {
			if (props.onClick) {
				props.onClick();
			}
		};

		return { t, buttonText, dismissAction, handleClick };
	}
};
</script>
