<template>
	<svg class="checkmark success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
		<circle class="checkmark_circle_success" cx="26" cy="26" r="25" fill="none"/>
		<path class="checkmark_check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" stroke-linecap="round"/>
	</svg>
</template>

<script>
export default {
	name: 'SuccessIcon',
}
</script>
