import {sendRequestJson} from '@/utils'

class ProductOrderRepository {
	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
	}

	async markAsPreparingProductOrder(data){
		const url = this.urlBase + "/world/api/productorder/startpreparation"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal marcando el pedido como preparando");
		}
	}

	async addProductOrderToHotTable(data){
		const url = this.urlBase + "/world/api/productorder/addtohottable"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal añadiendo a la hot table");
		}
	}

	async removeProductOrderToHotTable(data){
		const url = this.urlBase + "/world/api/productorder/removetohottable"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal eliminando a la hot table");
		}
	}

	async finishPreparation(data){
		const url = this.urlBase + "/world/api/productorder/finishpreparation"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal finalizando el pedido");
		}
	}

	async serveProductOrders(data){
		const url = this.urlBase + "/world/api/productorder/serve"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal sirviendo el pedido");
		}
	}

	async dispatchFireableProductOrders(data){
		const url = this.urlBase + "/world/api/productorder/dispatch-fireable"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal al marchar el dishClass");
		}
	}

}

export const productOrderRepository = new ProductOrderRepository();
