import {sendRequestJson} from '@/utils'

class MenuRepository {
	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('MenuRepository con urlBase: ', this.urlBase)
	}

	async getAvailableMenus() {
		const url = this.urlBase + "/world/api/menu/get"
		try {
			return await sendRequestJson({data:{}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo los productos del menu.");
		}
	}

	async getAvailableMenuProducts() {
		const url = this.urlBase + "/world/api/product/menu/get"
		try {
			return await sendRequestJson({data:{}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo los productos que son menú.");
		}
	}

	async updateMenuProduct(menuProduct) {
		const url = this.urlBase + "/world/api/product/menu/update"
		try {
			console.log("Si todo va bien actualizamos menuProduct ", menuProduct)
			return await sendRequestJson({data:{menuProduct}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando el producto menú.");
		}
	}

	async sendReview(data){
		const url = this.urlBase + "/world/api/review/create"
		try {
			console.log("Enviamos la review ", data)
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal creando la review.");
		}
	}

	async addTextToReview(data){
		const url = this.urlBase + "/world/api/review/addtext"
		try {
			console.log("Enviamos el texto para la review ", data)
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando la review.");
		}
	}

	registerClickReview(data){
		const url = this.urlBase + "/world/api/review/update"
		try {
			console.log("Enviamos el click para la review ", data)
			sendRequestJson({data, url, method:"POST"}).then(
				result => console.log(result)
			);
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando la review.");
		}
	}

}

export const menuRepository = new MenuRepository();
