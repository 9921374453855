import {i18n} from '@/i18n';
import {translationRepository} from "@/service/repository/translationRepository";

const state = {
	translations: {},
};

const getters = {
	getTranslation: (state) => (key) => {
		return state.translations[key] || key; // Return key if translation not found
	},
};

const actions = {
	async fetchTranslations({commit, state}, {keys, locale, venueId, venueName}) {

		if (!i18n) {
			console.error('i18n instance is not provided');
			return;
		}
		// Filter out keys that are already cached in the state
		const keysToFetch = keys.filter((key) => !state.translations[key]);

		if (keysToFetch.length === 0) {
			//return; // No need to fetch anything if all keys are cached
		}

		let data = {
			locale,
			venueName,
			venueId,
			keys: keysToFetch, // Pass only the required keys to optimize the request
		};

		try {
			const translations = await translationRepository.getTranslations(data);

			if (!translations) {
				console.error('Error: No translations found in response:', translations);
				return;
			}

			console.log("Fetched translations:", translations);
			commit('setTranslations', translations);

			// Ensure the 'dynamic' scope exists before merging
			if (!i18n.global.getLocaleMessage(locale).dynamic) {
				i18n.global.setLocaleMessage(locale, {
					...i18n.global.getLocaleMessage(locale),
					dynamic: {}
				});
			}

			// Integrate fetched translations into Vue I18n under the 'dynamic' scope
			/*i18n.global.mergeLocaleMessage(locale, {
				dynamic: translations,
			});*/

			// Get the current locale messages
			const currentMessages = i18n.global.getLocaleMessage(locale);

			if (!currentMessages.dynamic) {
				currentMessages.dynamic = {};
			}

			Object.assign(currentMessages.dynamic, translations);


			i18n.global.setLocaleMessage(locale, currentMessages);

			const afterMessages = i18n.global.getLocaleMessage(locale);

			console.log("Y otra vez después de asignar", afterMessages)

		} catch (error) {
			console.error('Error fetching translations:', error);
		}
	},
};

const mutations = {
	setTranslations(state, translations) {
		// Merge the new translations with the existing ones in the state
		state.translations = {...state.translations, ...translations};
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
