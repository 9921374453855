<template>
	<div v-if="hasContent" :id="category.id + '-' + category.name">
		<h2>{{ category.name }}</h2>
		<!-- Renderiza productos de la categoría actual -->
		<div v-for="product in category.products" :key="'card-availability' + product.id" class="extras-list-content mb-2">
			<copilot-product-card-availability-manager :product="product"></copilot-product-card-availability-manager>
		</div>
		<!-- Renderiza subcategorías de forma recursiva -->
		<div v-if="category.children && category.children.length">
			<CopilotProductAvailabilityManagerCategory
				v-for="child in category.children"
				:key="child.id"
				:category="child"
			></CopilotProductAvailabilityManagerCategory>
		</div>
	</div>
</template>

<script>
import CopilotProductCardAvailabilityManager from "@/components/CopilotProductCardAvailabilityManager/CopilotProductCardAvailabilityManager";

export default {
	name: "CopilotProductAvailabilityManagerCategory",
	components: {
		CopilotProductCardAvailabilityManager
	},
	props: {
		category: {
			type: Object,
			required: true,
		},
	},
	computed: {
		hasContent() {
			// Verificar si hay productos en la categoría actual
			const hasProducts = this.category.products && this.category.products.length > 0;

			// Verificar si alguna subcategoría tiene contenido
			const hasChildContent = this.category.children && this.category.children.some(child => {
				return child.products.length > 0 ||
					(child.children && child.children.some(grandChild => grandChild.products.length > 0));
			});

			return hasProducts || hasChildContent;
		}
	}
};
</script>

<style scoped>
/* Puedes añadir estilos personalizados aquí si los necesitas */
</style>
