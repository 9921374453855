<template>
	<button  type="button" class="btn btn-outline-primary btn-sm w-100" @click="toggleDropdown">
		<span v-if="selectedTables.length === 0">Selecciona una mesa</span>
		<span v-else>
        <span v-for="(names, room) in groupedTablesByRoom()" :key="room">
           <table-icon fill-color="black"></table-icon> {{ names }} | {{ room }}
        </span>
      </span>
	</button>

	<div class="offcanvas offcanvas-bottom" tabindex="-1"  :id="'modalCopilotSittingSelectedTable'" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header mb-0">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">Selecciona la mesa</h5>
			<button type="button"  @click="closeModal('modalCopilotSittingSelectedTable')" class="btn-close" aria-label="Close"></button>
		</div>

		<div class="offcanvas-body p-3">
			<form>
				<p><strong>Mesa actual:</strong></p>
				<div class="mb-3">
					<div v-if="selectedTables.length === 0">Selecciona una mesa</div>
					<div v-else v-for="table in selectedTables" :key="table.table_id" class="selected-table">
					<span @click="removeTable(table)">
						{{ table.name }} | {{ getRoom(table.name) }}
					</span>
					</div>
				</div>

				<div class="dropdown">
					<div
						v-for="table in localTables"
						:key="table.table_id"
						@click="toggleSelection(table)"
						:class="{ selected: isSelected(table) }"
					>
						{{ table.name }} | {{ getRoom(table.name) }}
					</div>
				</div>

				<div class="row justify-content-center mt-3">
					<div class="col-12 col-md-auto">
						<button :disabled="tableSelectDisabled" class="btn btn-primary w-100"  @click="closeModal('modalCopilotSittingSelectedTable')">
							<span>Seleccionar</span>
						</button>
					</div>
				</div>
			</form>
		</div>

		<div class="offcanvas-footer">
			<DismissButton :is-modal="false"/>
		</div>
	</div>

</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import * as bootstrap from 'bootstrap'
import TableIcon from "@/core/icons/TableIcon";
import {hideOffcanvas} from "@/utils";
import DismissButton from "@/core/DismissButton";

export default {
	name: 'CopilotSittingSelectTable',
	emits: ['update-selected-tables'],
	components: {DismissButton, TableIcon},
	inject: {
		reservationRepository: 'reservationRepository',
		copilotRepository: 'copilotRepository',
	},
	data() {
		return {
			selectedTables: [],
			dropdownOpen: false,
			isLoadingUpdateTable: false
		}
	},
	watch: {
		selectedTables: {
			handler(newValue) {
				this.$emit('update-selected-tables', newValue);
			},
			deep: true
		}
	},
	computed: {
		...mapGetters('copilot', ['tables']),
		localTables() {
			return this.tables.filter(table => table);
		},
		tableSelectDisabled(){
			return !this.selectedTables.length
		}

	},
	beforeMount() {

	},
	mounted() {
	},
	beforeUnmount() {
	},
	methods: {
		closeModal(id) {
			event.stopPropagation();
			event.preventDefault();
			hideOffcanvas(id);
		},
		toggleDropdown() {
			event.stopPropagation();
			const modalId = document.getElementById('modalCopilotSittingSelectedTable' )
			const elementModal = new bootstrap.Offcanvas(modalId)
			elementModal.show()

			if (!this.tableSelectDisabled) {
				// this.dropdownOpen = !this.dropdownOpen;
			}
		},
		toggleSelection(table) {
			event.stopPropagation();
			console.log('La mesa seleccionada', this.selectedTables)
			const index = this.selectedTables.findIndex(t => t.table_id === table.table_id);
			if (index === -1) {
				this.selectedTables.push(table);
			} else {
				console.log(this.selectedTables.length, !this.selectedTables.length)
				if (this.selectedTables.length === 1) {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `Tienes que seleccionar al menos una mesa.`,
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
					this.dropdownOpen = false;
					return
				}
				this.selectedTables.splice(index, 1);
			}
		},
		removeTable(table) {
			const index = this.selectedTables.findIndex(t => t.table_id === table.table_id);
			if (index !== -1) {
				if (this.selectedTables.length === 1) {
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `Tienes que seleccionar al menos una mesa.`,
							showToast: true,
							idToast: "incorrectlyToast"
						}
					});
				} else {
					this.selectedTables.splice(index, 1);
					store.commit("copilot/setToast", {
						toast: {
							toastMessage: `Mesa ${this.tables.find(t => t.table_id === table.table_id).name} removida correctamente.`,
							showToast: true,
							idToast: "correctlyToast"
						}
					});
				}
			}
		},
		isSelected(table) {
			return this.selectedTables.some(t => t.table_id === table.table_id);
		},
		getRoom(tableName) {
			const table = this.tables.find(t => {
				return t.name === tableName;
			});
			return table ? table.room : 'No Room';
		},
		closeDropdownOnOutsideClick(event) {
			if (this.$refs.multiselect && !this.$refs.multiselect.contains(event.target)) {
				this.dropdownOpen = false;
			}
		},
		groupedTablesByRoom() {
			const selectedTables =  this.selectedTables.reduce((acc, obj) => {
				if (!acc[obj.room]) {
					acc[obj.room] = obj.name;
				} else {
					acc[obj.room] += `, ${obj.name}`;
				}
				return acc;
			}, {});

			console.log('las agrupadas: ', selectedTables)
			return selectedTables;
		},
		clearSelectedTables(){
			this.selectedTables = []
		}
	}

}
</script>
