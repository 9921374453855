<template src="./copilot.html"></template>

<script>
import {DateTime} from 'luxon';
import * as bootstrap from 'bootstrap';
import FooterWelcom from "@/core/FooterWelcom";
import {mapGetters} from "vuex";
import {WebSocketClient} from "@/service/webSocketClient";
import store from "@/store";
import {hideAllModals, hideAllOffcanvas} from "@/utils";
import CheckCopilotModal from "@/components/checkCopilotModal/CheckCopilotModal";
import WebSocketWorker from '@/service/worker/wsWorker.worker.js'
import DismissButton from "@/core/DismissButton";
import CopilotMainComponent from "@/components/copilotMainComponent/CopilotMainComponent";
import CopilotContainerKitchen from "@/components/copilotContainerKitchen/copilotContainerkitchen";
import CopilotDailyMenu from "@/components/copilotDailyMenu/CopilotDailyMenu";
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import CopilotProductAvailabilityManager from "@/components/CopilotProductAvailabilityManager/CopilotProductAvailabilityManager";
//import { useI18n } from 'vue-i18n';
import {i18n, changeLocale, getLanguageName} from "@/i18n";
import {useI18n} from "vue-i18n";
import {ref} from "vue"; // Import the available locales

export default {
	name: 'CopilotWelcom',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		speakService: 'speakService',
		listenService: 'listenService',
		harvisService: 'harvisService',
		cameraService: 'cameraService',
		copilotRepository: 'copilotRepository',
		dateFormatterService: 'dateFormatterService',
		menuRepository: 'menuRepository',
	},
	data() {
		return {
			webSocketClient: WebSocketClient,
			notificationModeLocal: "0",
			selectedRoleLocal: this.selectedRole,
			isNotificationPlaying: false,
			inputs: [{id: 1, placeholder: "Nombre del plato:"}],
			menuCategories: [],
		};
	},
	setup() {
		const { t, locale } = useI18n(); // Extract the translation function `t` and the reactive `locale`
		const availableLocales = store.getters['venue/locales']; // Available locales from `i18n.js`
		const selectedLocale = ref(locale.value); // Make `selectedLocale` reactive

		// Method to change the locale
		const handleChangeLocale = (newLocale) => {
			changeLocale(i18n, newLocale);
			locale.value = newLocale; // Update `locale` directly
			selectedLocale.value = newLocale;
		};

		return {
			t,
			locale,
			availableLocales,
			selectedLocale,
			handleChangeLocale,
			getLanguageName,
		};
	},
	computed: {
		...mapGetters('menu', ['menuProducts', 'menusVenue']),
		...mapGetters('user', ['user']),
		...mapGetters('config', ['websocketUrl', 'websocketPort', 'isProductionEnvironment']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'venueId', 'reservationManagerUrl', 'menuServices']),
		...mapGetters('copilot', ['view', 'availableRoles', 'pendingOrders', 'selectedRole', 'selectedDate', 'reservations', 'accounts', 'notifications', 'assistantNotifications', 'isPlayNotificationSound', "isLoadingGetReservations", "isShowToastScanQr", "newNotification", "isKitchenSelectedRole", "commanderReservation", 'notificationMode', 'tables']),
		roleFilteredNotifications() {
			let selectedRoleObject = this.availableRoles.find(role => role.id === this.selectedRole);
			return this.assistantNotifications.filter(notification => {
				// If selectedRole is 0, include the notification by default
				if (Number(this.selectedRole) === 0 &&
					notification.type !== "order_new_food" &&
					notification.type !== "order_new_distilled" &&
					notification.type !== "dispatch_fireable_product_order") {
					return true;
				}

				if (selectedRoleObject == null) {
					return false;
				}
				let selectedRoleNotificationTypes = selectedRoleObject.notificationTypes;

				if (selectedRoleNotificationTypes == null) {
					return false;
				}

				// Extract the IDs of the selectedRole's notificationTypes
				const selectedRoleNotificationTypeIds = selectedRoleNotificationTypes.map(nt => nt.id);

				// Check if any of the notification's notificationTypes match any of the selectedRole's notificationTypes
				return notification.notificationTypes && Array.isArray(notification.notificationTypes)
					? notification.notificationTypes.some(notificationType =>
						selectedRoleNotificationTypeIds.includes(notificationType.id)
					)
					: false;
			});

		},
		latestNotification() {
			return this.roleFilteredNotifications.length > 0 ? this.roleFilteredNotifications[0] : null;
		},
		notificationHistory() {
			return this.roleFilteredNotifications.length > 1 ? this.roleFilteredNotifications.slice(1) : [];
		},
		rolSelectedName() {
			const role = this.availableRoles.find(i => i.id === this.selectedRole);
			hideAllOffcanvas();
			return role ? role.name : 'Todos los mensajes';
		},
		menus() {
			return this.menusVenue
		},
	},
	watch: {
		async newNotification() {
			if (this.isNotificationPlayable(this.newNotification)) {
				console.log('La notificación se debe escuchar');
				await this.speakService.enqueueNotification(this.newNotification);
				store.commit('copilot/setIsPlayNotificationSound', {isPlayNotificationSound: false});
			} else {
				console.log('La notificación no se debe escuchar');
			}
		},
		selectedRole: {
			handler(newValue) {
				this.selectedRoleLocal = newValue;
			},
			immediate: true // Esto asegura que se establece el valor inicial al montar el componente
		},
		selectedRoleLocal(newValue) {
			if (newValue !== this.selectedRole) {
				store.commit('copilot/setSelectedRole', {selectedRole: newValue});
			}
		},
		isShowToastScanQr(newValue, oldValue) {
			console.log('Cambiando el valor de la valiable computada', newValue, oldValue)
			if (newValue === true) {
				this.showToast('scannerCorrectly');
				hideAllModals();
				store.commit('copilot/setIsShowToastScanQr', {isShowToastScanQr: null})
			}
			if (newValue === false) {
				this.showToast('scannerIncorrectly');
				store.commit('copilot/setIsShowToastScanQr', {isShowToastScanQr: null})
			}
		},
		notificationModeLocal(newValue, oldValue) {
			console.log('Ha cambiado el modo notificacion de ', oldValue, ' a ', newValue);
			store.commit('copilot/setNotificationMode', {notificationMode: newValue})
		},

	},
	components: {
		FooterWelcom,
		CheckCopilotModal,
		DismissButton,
		CopilotMainComponent,
		CopilotContainerKitchen,
		CopilotDailyMenu,
		OrdersProductCard,
		CopilotProductAvailabilityManager
	},
	created() {
		this.worker = new WebSocketWorker();
		this.worker.onmessage = async (event) => {
			console.log('Mensaje recibido del worker:', event.data);
			switch (event.data.type) {
				case 'response':
					console.log('Respuesta del worker:', event.data.payload);
					break;

				case 'pong':
					await this.speakService.playNotificationAlertSound();
					break;
			}
		}

		this.worker.postMessage({type: 'check', payload: "Esto es una prueba"});
	},
	async beforeMount() {
		let uidCopilot = 'COPILOT' + '_' + this.venueId;
		console.log("Iniciando conexión al websocket con ", uidCopilot, 'le vamos a pasar el component')
		this.webSocketClient = new WebSocketClient(this, this.websocketUrl, this.websocketPort);
		this.webSocketClient.connect(uidCopilot);

		// this.worker.postMessage({
		// 	type: 'connect',
		// 	payload: {url: this.websocketUrl, port: this.websocketPort, clientId: uidCopilot}
		// });

		document.body.setAttribute('data-bs-theme', 'copilot-dark');
	},
	async mounted() {

		if (this.isProductionEnvironment) {
			this.showInitialCheckModal();
		}

		this.isLoad = true;

		this.setHeightTo100vh();
		this.selectMenuCategories()

		/*const link = document.createElement('link');
		link.rel = 'manifest';
		link.href = '/manifest.json'; // Adjust the path as needed if it's placed elsewhere
		document.head.appendChild(link);*/

	},
	/*beforeUnmount() {
		const manifestLink = document.querySelector('link[rel="manifest"]');
		if (manifestLink) {
			document.head.removeChild(manifestLink);
		}
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.getRegistrations().then((registrations) => {
				for (let registration of registrations) {
					registration.unregister();
				}
			});
		}
	},*/
	methods: {
		filterMenuCategoriesByProducts(menusFiltered) {
			console.log('desde menu', menusFiltered)
			this.selectMenuCategories(menusFiltered)
		},
		selectMenuCategories(menus = null) {
			const foodMenu = {
				id: 'food',
				name: 'Comida',
				menuCategories: menus ?
					menus.filter(menu => menu.menuTypeName === 'Comida').flatMap(menu => menu.menuCategories)
					: this.menus.filter(menu => menu.menuTypeName === 'Comida').flatMap(menu => menu.menuCategories),
			};
			const drinkMenu = {
				id: 'drink',
				name: 'Bebida',
				menuCategories: menus ?
					menus.filter(menu => menu.menuTypeName === 'Bebida').flatMap(menu => menu.menuCategories)
					: this.menus.filter(menu => menu.menuTypeName === 'Bebida').flatMap(menu => menu.menuCategories),
			};
			this.menuCategories = [foodMenu, drinkMenu];

		},

		showInitialCheckModal() {
			const modalElement = document.getElementById('check-copilot-modal');
			let myModal = bootstrap.Modal.getInstance(modalElement);
			myModal = new bootstrap.Modal(modalElement);
			myModal.show();
		},
		formatNotificationDate(notification) {
			let dateTime = DateTime.fromFormat(notification.dateCreated, "dd/MM/yyyy HH:mm:ss");

			return dateTime.toFormat("HH:mm:ss");
		},

		showToast(toastId) {
			const toastElement = document.getElementById(toastId);
			const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
			toastInstance.show();
		},

		async updateReservations() {
			this.isLoading = true;
			await store.dispatch('copilot/updateReservations');
			this.isLoading = false;
		},

		isNotificationPlayable(notification) {
			console.log('Vamos a comprobar que devuelve la funcion isNotificationPlayable')
			if (notification.creatorUserId === this.user.id){
				console.log("La notificación la ha creado el mismo usuario");
				return false;
			}

			console.log('Antes de: ', notification)
			if (Number(this.selectedRole) === 0 &&
				notification.type !== "order_new_food" &&
				notification.type !== "order_new_distilled" &&
				notification.type !== "dispatch_fireable_product_order") {
				return true;
			}

			let selectedRoleObject = this.availableRoles.find(role => role.id === this.selectedRole)

			console.log('El selectedRoleObject', selectedRoleObject)
			if (selectedRoleObject == null) {
				return false;
			}

			let selectedRoleNotificationTypes = selectedRoleObject.notificationTypes;
			if (selectedRoleNotificationTypes == null) {
				return false;
			}

			// Extract the IDs of the selectedRole's notificationTypes
			//const selectedRoleNotificationTypeNames = selectedRoleNotificationTypes.map(nt => nt.name);

			//console.log('selectedRoleNotificationTypeNames', selectedRoleNotificationTypeNames, 'la notificacion', notification)
			//const notificationRoles = notification.roles.map(role => role.id)
			//console.log('Los rolesNotifications', notificationRoles);

			const notificationRoleIds = notification.roles.map(role => role.id);

			// Check if the selectedRoleObject.id is included in the notification's roles
			return notificationRoleIds.includes(selectedRoleObject.id);
		},

		async updateNotifications() {
			await store.dispatch('copilot/updateOrdersAndNotifications')
		},

		replayNotification(notification) {
			this.speakService.playNotification(notification)
		},

		setHeightTo100vh() {
			var appElement = document.getElementById('welcom-copilot');
			if (appElement) {
				appElement.style.height = '100vh';
				appElement.style.display = 'flex';
				appElement.style.flexDirection = 'column';
			}

			var footerElement = document.getElementsByTagName('footer')[0];
			if (footerElement) {
				footerElement.style.marginTop = 'auto';
			}
		},

	},
}
</script>
