<template>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvas-products-edit-status">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4 m-0">Disponibilidad de platos</div>
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body p-3">
			<div class="input-group mb-3">
				<span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
				<input
					ref="searchInput"
					type="text"
					class="form-control"
					placeholder="Escribe aquí para buscar tu producto"
					v-model="searchQuery"
					@input="search"
				>
				<span class="input-group-text" style="cursor: pointer;" @click="clearInputSearch">
					<i class="fa-solid fa-circle-xmark"></i>
				</span>
			</div>
			<p class="mb-3" v-if="false">
				Recuerda: al final del día, todos los platos estarán disponibles.
			</p>
			<div v-for="menu in filteredProducts" :key="menu.id" :id="menu.id">
				<CopilotProductAvailabilityManagerCategory
					v-for="category in menu.menuCategories"
					:key="category.id"
					:category="category"
				></CopilotProductAvailabilityManagerCategory>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import CopilotProductAvailabilityManagerCategory
	from "@/components/copilotProductAvailabilityManagerCategory/CopilotProductAvailabilityManagerCategory";

export default {
	name: "CopilotProductAvailabilityManager",
	components: {
		CopilotProductAvailabilityManagerCategory,
	},
	data(){
		return {
			searchQuery: '',

		}
	},
	computed: {
		...mapGetters('menu', ['menusVenue']),
		filteredProducts() {
			if (!this.searchQuery) return this.menusVenue;
			return this.filterMenus(this.normalizeText(this.searchQuery));
		}
	},
	mounted() {
	},
	methods:{
		search() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.$forceUpdate(); // Fuerza la actualización para recalcular los productos filtrados
			}, 300);
		},

		filterMenus(query) {
			// Función recursiva para filtrar categorías y subcategorías
			const filterCategory = (category) => {
				// Filtrar productos en la categoría actual que coincidan con la consulta
				const filteredProducts = category.products.filter(product =>
					this.matchesQuery(product.name, query)
				);

				// Filtrar recursivamente las subcategorías
				const filteredChildren = (category.children || []).map(filterCategory).filter(Boolean);

				// Crear un nuevo objeto de categoría si hay productos o subcategorías filtradas
				if (filteredProducts.length || filteredChildren.length) {
					return {
						...category,
						products: filteredProducts,
						children: filteredChildren
					};
				}
				return null;
			};

			// Aplicar el filtro recursivo a las categorías de menusVenue
			return this.menusVenue.map(menu => {
				const newMenuCategories = menu.menuCategories.map(filterCategory).filter(Boolean);

				// Crear un nuevo objeto de menú si hay categorías filtradas
				return newMenuCategories.length ? { ...menu, menuCategories: newMenuCategories } : null;
			}).filter(Boolean);
		},

		matchesQuery(name, query) {
			return this.normalizeText(name).includes(query);
		},

		normalizeText(text) {
			return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
		},

		clearInputSearch() {
			this.searchQuery = '';
			this.search();
		},
	}
}
</script>
