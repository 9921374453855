<template src="./modalScannerQR.html"></template>

<script>
import * as bootstrap from "bootstrap";
import store from "@/store";

import {mapGetters} from "vuex";
import {hideAllModals} from "@/utils";
import DismissButton from "@/core/DismissButton";
import {walkingService} from "@/service/walkingService";

export default {
	name: 'ModalScannerQR',

	data() {
		return {}
	},
	watch: {},
	props: {},
	inject: {
		cameraService: 'cameraService',
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName']),
		...mapGetters('copilot', ['reservationId']),
		...mapGetters('config',['weWelcomUrl']),

		isInKiosksRoute() {

			return this.$router.path === '/kiosk';
		},
	},
	components: {
		DismissButton
	},
	beforeMount() {
	},
	mounted() {
		console.log(this.resourceId)
	},
	methods: {
		// showToast(toastId) {
		// 	const toastElement = document.getElementById(toastId);
		// 	const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
		// 	toastInstance.show();
		// },

		showModalAlert(modalId) {
			const modalCheckInbox = new bootstrap.Modal(document.getElementById(modalId), {
				backdrop: 'static',
				keyboard: false
			});
			modalCheckInbox.show();
		},
		async openQRInvitation() {
			this.cameraService.stopCamera()

			console.log({id:this.reservationId})
			hideAllModals();
			store.commit('copilot/setSelectedReservationId', { reservationId:this.reservationId });

			await walkingService._openQRInvitation(this.reservationId);
		},
		stopCameraScanner(){
			this.cameraService.stopCamera()
		}

	}
}
</script>
