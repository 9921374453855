<template>
	<label
		class="product-card-client product-card-client-list"
		:for="`option_checkbox_${product.name}-${product.productOrderId}`"
	>
		<div class="product-card-body">
			<img
				v-if="showPictureMenu !== null && imageUrl()"
				class="product-card-img"
				:src="imageUrl()"
				@error="handleImageError"
				alt="..."
			/>
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">
						{{ product.name }}
					</div>
				</div>
			</div>
			<div class="product-card-btns flex-column">
				<select
					v-if="!isUpdatingProduct"
					v-model="availabilityLocal"
					@change="handleChangeSelect"
					@focus="storePreviousValue"
					:class="selectClass"
					class="form-select"
					style="width: 10rem;"
					aria-label="Default select example"
				>
					<option value="1">Disponible</option>
					<option value="0">Sin Stock</option>
					<option value="2">Sugerencia del chef</option>
					<option value="-1">Fuera de temporada</option>
				</select>
				<div class="" v-else>
					Actualizando...<SpinnerIcon/>
				</div>
			</div>
		</div>
	</label>
</template>


<script>
import { mapGetters } from 'vuex';
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'CopilotProductCardAvailabilityManager',
	components: {SpinnerIcon  },
	inject: {
		copilotRepository: 'copilotRepository',
	},
	props: {
		product: {
			type: Object,
			required: true,
			default: null,
		},
	},
	data() {
		return {
			isUpdatingProduct: false,
			showPictureMenu: null,
			availabilityLocal: 1,
			previousAvailability: null,
			isChecked: false,
		};
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue']),
		availability() {
			return this.product.menuCategoryProductInfo.availability;
		},
		productImg() {
			return `${this.urlVenue}/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
		},
		selectClass() {
			switch (Number(this.availabilityLocal)) {
				case 1:
					return 'availability';
				case 0:
					return 'not-availability';
				case 2:
					return 'chef-suggestion';
				case -1:
					return '';
				default:
					return '';
			}
		},
	},
	watch: {
		'product.topChoice'(newVal) {
			this.isChecked = newVal === 'SI';
			if (this.isChecked) {
				this.availabilityLocal = 2;
			}
		},
		'product.menuCategoryProductInfo.availability'(newVal) {
			if (!this.isChecked) {
				this.availabilityLocal = Number(newVal);
			}
		},
	},
	mounted() {
		this.checkImage();
		this.isChecked = this.product.topChoice === 'SI';
		if (this.isChecked) {
			this.availabilityLocal = 2;
		} else {
			this.availabilityLocal = Number(this.availability);
		}
	},
	methods: {
		storePreviousValue() {
			this.previousAvailability = this.availabilityLocal;
		},
		imageUrl() {
			return this.productImg;
		},
		checkImage() {
			const img = new Image();
			img.src = this.productImg;

			img.onerror = () => {
				this.showPictureMenu = null;
			};

			this.showPictureMenu = true;
		},
		handleImageError() {
			this.showPictureMenu = null;
		},
		async handleChangeSelect() {
			this.isUpdatingProduct = true;
			const resultUpdate = await this.updateAvailability(this.availabilityLocal);

			if(resultUpdate.result < 0){
				this.availabilityLocal = this.previousAvailability;
			}

			this.isUpdatingProduct = false;
		},
		async updateAvailability(state) {
			const data = {
				productSku: this.product.sku,
				menuCategoryId: this.product.menuCategoryProductInfo.menuCategoryId,
				state: Number(state),
			};
			const result = await this.copilotRepository.updateAvailabilityProduct(data);
			console.log(result)

			return result;
		},
	},
};
</script>
