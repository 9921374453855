<template>
	<header class="header-container" :class="{ 'header-we-order': isWeOrdersRoute }">
		<div class="header-content" :style="{backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.667)),url('${urlVenue}/world/img/${imageRestaurant}/header/header.jpg ')`}">
			<h1>{{ textTitle }}</h1>
			<h2>{{ subTitle }}</h2>
		</div>
	</header>
</template>

<script>

import {mapGetters} from "vuex";

export default {
	inject: {
	},
	data() {
		return {
			isWeOrdersRoute: this.checkIfCopilotRoute(this.$route.path)
		};
	},
	props: {
		subTitle:String,
		imageRestaurant:String,
		textTitle:String,
		urlRestaurant:String,
	},
	watch: {
		'$route'(to) {
			this.isWeOrdersRoute = this.checkIfCopilotRoute(to.path);
		}
	},
	components: {
	},
	computed: {
		...mapGetters({
			urlVenue: 'venue/urlVenue',
		}),
	},
	beforeMount() {
	},
	mounted() {
		this.isWeOrdersRoute = this.checkIfCopilotRoute(this.$route.path);
	},
	methods: {
		checkIfCopilotRoute(path) {
			return path === '/menu';
		}
	}
};
</script>
