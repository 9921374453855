<div class="container-md p-0 px-md-3">
	<div class="container-grid">
		<div class="access-btns-grid">
			<!-- Calendario	-->
			<section class="section-primary mt-0">
				<div class="row justify-content-center align-items-end gap-3 gap-md-0">
					<div class="col-12 col-md-4">
						<header class="header-notification text-center py-0">
							<div class="header-notification-title">WECOPILOT
								<span class="vr mx-1"></span><span class="fw-normal">{{ businessName }}</span></div>
						</header>
					</div>
					<div class="container-select col-12 col-md-4">
						<div class="row align-items-center">
							<div class="col-auto">
								Rol:
							</div>
							<div class="col">
								<select class="form-select" v-model="selectedRoleLocal">
									<option value="0" selected>{{ t('copilot.allRoles')}}</option>
									<option v-for="role in availableRoles" :key="role.id" :value="role.id">
										{{ role.name }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="date-picker__container col-12 col-md-4">
						<div class="input-group">
							<span @click="subtractOneDay" class="btn btn-secondary"><i class="fas fa-angle-left"></i></span>
							<DatePicker :store-module="'copilot'"/>
							<span @click="addOneDay" class="btn btn-secondary"><i class="fas fa-angle-right"></i></span>
						</div>
					</div>
				</div>
			</section>

			<!-- Btn notifications	-->
			<div class="my-2 px-3 px-md-0">
				<button v-if="notificationMode > 0" class="btn btn-outline-primary w-100" data-target="#pending-notifications-copilot-modal"
						data-toggle="modal" type="button" @click="showModal('pending-notifications-copilot-modal')">
					Notificaciones
				</button>
			</div>
		</div>
		<div class="notifications-grid">
			<!--	Btn rol Barra	-->
			<div v-if="isVisibleDrinkOrdersOffCanvas && sortedReservationsWithProducts.length " class="my-2 px-3 px-md-0">
				<button class="btn btn-primary w-100 position-relative" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-order-drink">
					<span class="badge badge-secondary badge-btn">+{{sortedReservationsWithProducts.length}}</span>
					Bebidas pendientes
				</button>
			</div>

			<!--  Cuentas pedidas	-->
			<section v-if="notifications[0] && showTicketRequestNotifications" class="section-card">
				<div v-if="isLoadingNotification" class="d-flex flex-column justify-content-center align-items-center my-4 w-100">
					Cargando...
					<div class="spinner-border" role="status">
					</div>
				</div>

				<div v-else>
					<h2 v-if="!notificationPaymentOnline">Han solicitado la cuenta</h2>
					<h2 v-else>Pago online en proceso</h2>
					<div class="account-container">
						<div class="account-data">
							<div class="account-data-header">
								<div v-if="notifications[0].reservationName">
									<i class="fa-solid fa-qrcode"></i>
									{{ notifications[0].reservationName }}
								</div>
								<div>
									<TableIcon/>
									{{ notifications[0].reservation.table }}
									<div class="vr mx-2"></div>
									{{ notifications[0].reservation.roomName }}
								</div>
								<div>
									<i class="fas fa-clock"></i> {{ elapsedTime }}
								</div>
							</div>
							<div class="account-data-body">
								<div class="service-meta justify-content-center">
									Solicitada por
									<b>{{ notifications[0].target }} </b>

									<span v-if="notificationReview">
<!--									#ffcc00-->
									 <i class="fas fa-star" style="color: var(--bs-secondary)"></i>
									<b>{{ notificationReview }}</b>
								</span>
								</div>
								<div class="service-meta justify-content-center">
									Pago <b>{{ paymentMethodName(notifications[0].message) }}</b>
								</div>
							</div>
						</div>
						<div class="text-center">
							<button class="btn btn-secondary w-100" @click="sendNotification(notifications[0].id)">
								<span v-html="copilotButtonTextAccount"></span></button>
						</div>
						<div v-if="false" class="in-list-content-collapse-order orders-history">
							<ul>
								<li v-for="(product, index) in notifications[0].data.ticket.items" :key="index">
									<div class="list-item list-total-product">
										<div>{{ product.quantity }}</div>
										<div>{{ product.name }}</div>
										<div>{{ formatPrice(product.priceByUnit) }}</div>
									</div>
								</li>
							</ul>
						</div>
						<!--						<button class="btn btn-success w-100"  @click="sendNotification(notifications[0].id)"><span v-html="copilotButtonTextAccount"></span></button>-->
					</div>
				</div>

				<div v-if="notifications && notifications.length > 1" class="alert alert-account" role="alert">
					<i class="fas fa-bell"></i> <span> +{{ notifications.length - 1 }} cuentas</span>
				</div>
			</section>

			<!--  Productos mesa caliente	-->
			<CopilotOrdersContainerKitchen v-if="serviceEventPendingService && serviceEventPendingService.length > 0"/>

			<!--  Pedidos realizados	-->
			<section v-if="pendingOrders && pendingOrders.length > 0" class="section-card">
				<copilot-orders-container :reservations="reservations"></copilot-orders-container>
			</section>
		</div>
		<div class="body-grid">
			<!--  Pantalla de carga	-->
			<div v-if="isLoadingGetReservations">
				<section class="section-service placeholder-glow">
					<div class="copilot-schedule">
						<span class="placeholder col-6"></span>
						<span class="placeholder col-2"></span>
						<span class="placeholder col-2"></span>
					</div>
				</section>
				<section class="section-service placeholder-glow">
					<div class="copilot-schedule">
						<span class="placeholder col-6"></span>
						<span class="placeholder col-2"></span>
						<span class="placeholder col-2"></span>
					</div>
				</section>
				<section class="section-service placeholder-glow">
					<div class="copilot-schedule">
						<span class="placeholder col-6"></span>
						<span class="placeholder col-2"></span>
						<span class="placeholder col-2"></span>
					</div>
				</section>

			</div>
			<!--  Servicios	-->
			<div v-else id="accordionAllServices">
				<copilot-container-reservation v-for="service in filteredReservesServices"
											   v-if="filteredReservesServices.length > 0 && filteredReservesServices.some(service => service.serviceReservations.length > 0)"
											   :service="service"  ></copilot-container-reservation>
				<div v-else>
					<section class="text-center container-section">
						<b>{{t('copilot.errorNotReservationsOrWalkin',{reservation:t('copilot.reservation'),walkin:t('copilot.walkin')})}}</b>
					</section>
				</div>
			</div>

			<div class="my-4 py-1 hidden-md"></div>
		</div>
	</div>
</div>

<!--  Botones de acceso rapido	-->
<transition name="fade">
	<nav v-show="navbarVisible" class="navbar navbar-primary navbar-bottom">
		<div class="container-navbar-bottom container-md gap-0">
			<button id="qr-scan-copilot-button" class="btn btn-navbar btn-column btn-square" @click="handleOptionClick('camera')">
				<i class="fas fa-camera"></i>
				<span>{{ t('copilot.scanReservationQR', {reservation: t('copilot.reservation')}) }}</span>
			</button>
			<button id="walk-in-copilot-button" class="btn btn-navbar btn-column btn-square" @click="handleOptionClick('walkin')">
				<i class="fas fa-walking"></i>
				<span>{{ t('copilot.walkin') }}</span>
			</button>
			<button id="sit-in-copilot-button" class="btn btn-navbar btn-column btn-square" data-bs-toggle="offcanvas" href="#modal-sit-in" role="button" aria-controls="modal-sitting">
				<SittingIcon fill-color="white"/>
				<span>{{ t('copilot.sitin') }}</span>
			</button>
			<button class="btn btn-navbar btn-column btn-square" @click="handleordenar">
				<i class="fas fa-microphone"></i>
				<span>{{ t('copilot.harvis') }}</span>
			</button>
			<div class="speeddial" :class="{ 'speeddial-opened': isOpen }">
				<ul class="speeddial-list">
					<li class="speeddial-item" style="transition-delay: 30ms;">
						<button class="speeddial-action btn btn-primary" data-bs-toggle="offcanvas" @click="isOpen = false" data-bs-target="#modalComments">
							<i class="fas fa-comment"></i>{{ t('copilot.SuggestionsOrIssues') }}
						</button>
					</li>
					<li class="speeddial-item" style="transition-delay: 90ms;">
						<button class="speeddial-action btn btn-primary" @click="handleOptionClick('newReserve')">
							<i class="fa-solid fa-phone-flip"></i>{{ t('copilot.newReservation',{reservation: t('copilot.reservation')}) }}
						</button>
					</li>
					<li class="speeddial-item" style="transition-delay: 60ms;">
						<button class="speeddial-action btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
							<i class="fas fa-assistive-listening-systems"></i>{{ t('copilot.notifications') }}
						</button>
					</li>
					<li class="speeddial-item" style="transition-delay: 120ms;">
						<button class="speeddial-action btn btn-primary" @click="handleOptionClick('google')">
							<GoogleIcon/> Valóranos en Google
						</button>
					</li>
					<li class="speeddial-item" style="transition-delay: 120ms;">
						<button class="speeddial-action btn btn-primary" @click="handleOptionClick('plano')">
							<i class="fas fa-sitemap"></i>{{ t('copilot.plano') }}
						</button>
					</li>
				</ul>
				<button type="button" class="speeddial-button btn btn-navbar btn-column" @click="toggleSpeedDial">
					<i class="fas fa-times"></i>
				</button>
			</div>
		</div>
	</nav>
</transition>

<!--  Offcanvas Walkin	-->
<div id="modal-walkin" aria-hidden="true" class="offcanvas offcanvas-bottom fade" tabindex="-1">
	<div class="offcanvas-header mb-0">
		<div id="exampleModalLabel" class="offcanvas-title h4"><i class="fas fa-walking"></i> {{ t('copilot.offcanvasWalkin.title', { walkin: t('copilot.walkin') }) }}
		</div>
		<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
	</div>
	<div class="offcanvas-body p-3">
		<form>
			<div class="row row-column-gap">
				<div class="col-12 col-md-6 m-0">
					<div class="input-group"><span class="input-group-text"><i class="fas fa-user"></i></span>
						<input id="input-name" v-model="walkInName" aria-label="" class="form-control"
							   :placeholder="`${t('copilot.offcanvasWalkin.placeHolderName')}`" type="text">
					</div>
					<div id="attentionNameWalkin" class="form-text">
						{{t('copilot.offcanvasWalkin.attentionNameWalkin')}}
					</div>
				</div>
				<div class="col-12 col-md-6 mb-3">
					<div class="input-group">
						<span class="input-group-text"><i class="fas fa-user-plus"></i></span>
						<input id="input-pax" v-model="walkInPax" aria-label="" class="form-control"
							   :placeholder="`${t('copilot.offcanvasWalkin.placeHolderPax')}`" min="1" type="number">
					</div>
				</div>
			</div>

			<select v-if="false" class="btn btn-secondary w-100 text-truncate btn-sm form-control mt-3 "
					@change="selectTableWalkin">
				<option selected value=null>Asignar automáticamente</option>
				<option v-for="table in tables" :key="table" :value="table.table_id">{{ table.name }} | {{
						table.room
																					 }}
				</option>
			</select>

			<transition name="fade">
				<div v-if="showErrorMessageWalkIn" id="walk-in-response-collapse" class="alert alert-danger" role="alert">
					{{ errorMessageWalkIn }}
				</div>
			</transition>


			<div class="row mt-3 justify-content-center">
				<div class="col-12 col-md-auto">
					<button id="create-walk-in-copilot-button" :disabled="walkInButtonDisabled || isLoadingBtnWalkin" class="btn btn-primary w-100" @click="handleNewWalkinButton">
						<span v-if="!isCreateWalkIn">{{t('copilot.offcanvasWalkin.createWalkinBtn', { walkin: t('copilot.walkin')})}}</span><span v-else><SpinnerIcon/></span>
					</button>
				</div>
			</div>
		</form>
	</div>
	<div class="offcanvas-footer">
		<DismissButton :is-modal="false" :on-click="closeModalWalkin"/>
	</div>
</div>

<!--  Offcanvas Sitting	-->
<copilot-sitting></copilot-sitting>

<!--  Offcanvas Pedidos de bebidas	-->
<CopilotOffcanvasHybridRole/>

<!--  Offcanvas Caja de comentarios	-->
<div id="modalComments" aria-hidden="true" aria-labelledby="modalCommentsLabel" class="offcanvas offcanvas-bottom fade"
	 tabindex="-1">
	<div class="offcanvas-header mb-0">
		<div id="modalCommentsLabel" class="offcanvas-title h4">Opiniones y Sugerencias</div>
		<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
	</div>
	<div class="offcanvas-body">
		<iframe
			height="600"
			src="https://docs.google.com/forms/d/e/1FAIpQLSffdvwENTp4J7iPf0rl8P6hTgN7Zg3bOKYSHCB6MVZjBphtAg/viewform"
			style="border: none; overflow: hidden;"
			title="Google Forms"
			width="100%">
		</iframe>
	</div>
	<div class="offcanvas-footer">
		<DismissButton :is-modal="false"/>
	</div>
</div>

<!--  Offcanvas nueva reserva	-->
<div id="offcanvas-new-reserve" aria-hidden="true" aria-labelledby="modal-new-reserve-label"
	 class="offcanvas offcanvas-bottom fade" tabindex="-1">
	<div class="offcanvas-header mb-0">
		<div id="modal-new-reserve-label" class="offcanvas-title h4">Nueva Reserva</div>
		<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
	</div>
	<div class="offcanvas-body p-0">
		<reservation-component source="copilot" @clear-form="clearNewReservationForm"></reservation-component>
	</div>
	<div class="offcanvas-footer">
		<DismissButton :is-modal="false"/>
	</div>
</div>

<div class="toast-container position-fixed end-0 p-3" style="top: 60px;">
	<div id="scannerCorrectly" aria-atomic="true" aria-live="assertive" class="toast align-items-center toast-success"
		 role="alert">
		<div class="d-flex">
			<div class="toast-body">
				Se ha escaneado correctamente el QR.
			</div>
			<button aria-label="Close" class="btn-close me-2 m-auto" data-bs-dismiss="toast" type="button"></button>
		</div>
	</div>
	<div id="scannerIncorrectly" aria-atomic="true" aria-live="assertive" class="toast align-items-center bg-danger"
		 role="alert">
		<div class="d-flex">
			<div class="toast-body">
				No se han podido escanear correstamente el QR.
			</div>
			<button aria-label="Close" class="btn-close me-2 m-auto" data-bs-dismiss="toast" type="button"></button>
		</div>
	</div>
</div>

<modal-scanner-qr/>

<copilot-pending-notifications></copilot-pending-notifications>

<!--  Offcanvas comandero	-->
<div id="offcanvasCopilotCommander" aria-labelledby="offcanvasExampleLabel" class="offcanvas offcanvas-bottom"
	 tabindex="-1">
	<div class="offcanvas-header mb-0">
		<div v-if="commanderReservation" id="offcanvasExampleLabel" class="offcanvas-title h4">
			Reserva de {{ commanderReservation.name }}
		</div>
		<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
	</div>
	<div id="offcanvas-body-commander" class="offcanvas-body p-0">
		<commander-welcom v-if="commanderReservation"></commander-welcom>
		<div class="my-4 py-3"></div>
	</div>
</div>

<!--Modal HARVIS-->
<div class="modal fade" id="modal-harvis" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-bottom modal-dialog-between">
		<div class="modal-content">
			<div class="modal-body">
				<h2 id="instructionsModalLabel">Comandos de voz H.A.R.V.I.S.</h2>
				<ul class="list-group list-group-flush">
					<li class="list-group-item">
						<b>Agregar mesa {{t('copilot.walkin')}}:</b> <br> <i>'<b>Nombre</b>, {{t('copilot.walkin')}}, <b>Número de Pax</b> personas'</i>
					</li>
					<li class="list-group-item">
						<b>Activar reserva:</b> <br> <i>'Activa la mesa, <b>Número de la mesa</b>'</i>
					</li>
					<li class="list-group-item">
						<b>Finalizar reserva:</b> <br> <i>'Finaliza la mesa, <b>Número de la mesa</b>'</i>
					</li>
				</ul>
			</div>
		</div>
		<div class="modal-content">
			<div class="modal-body">
				<div id="visualizer">
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
					<div class="bar"></div>
				</div>
				<div id="text-visualizer" class="text-center text-uppercase">escuchando...</div>
			</div>
			<div class="modal-footer">
				<DismissButton :is-modal="true" :on-click="handleCancelOrderHarvis"/>
				<button class="btn btn-primary" @click="repeatHandleOrdenar">
					<i class="fas fa-microphone"></i>
					<span>H.A.R.V.I.S.</span>
				</button>
			</div>
		</div>
	</div>
</div>

<ModalQrGoogle></ModalQrGoogle>

<ModalConfirmDishClassChanged/>

<CopilotModalQrReservation/>

<CopilotOffcanvasUpdatePaxReservation/>

<CopilotOffcanvasHistoricAccountReservation/>

<CopilotOffcanvasFinishReservation/>

<ModalRemoveProduct/>
