import store from "@/store";
import {showOffcanvas} from "@/utils";

export class WebSocketClient {

	static ACTION_ENABLE_MENU_BUTTON = 0;
	static ACTION_UPDATE_RESERVATION_ORDERS = 1;
	static ACTION_SEND_NOTIFICATION_USER_VALIDATE = 2;
	static ACTION_SEND_NOTIFICATION_RESERVATION_VALIDATE = 3;
	static ACTION_UPDATE_RESERVATION_IN_COPILOT = 4;
	static ACTION_UPDATE_NOTIFICATIONS_IN_COPILOT = 5;
	static ACTION_DISABLE_MENU = 6;
	static ACTION_SEND_NOTIFICATION = 7;
	static ACTION_ADD_SERVICE_EVENT = 9;
	static ACTION_UPDATE_SERVICE_EVENT = 10;
	static ACTION_UPDATE_A_SINGLE_PRODUCT_ORDER = 11;
	static ACTION_ADD_NEW_RESERVATION_IN_COPILOT = 12;
	static ACTION_UPDATE_MENUS = 13;
	static ACTION_UPDATE_RESERVATION_IN_MENU = 14;
	static ACTION_UPDATE_PRODUCT_AVAILABILITY = 15;
	static ACTION_UPDATE_PRODUCT_TOP_CHOICE = 16;
	static ACTION_UPDATE_MENU_PRODUCT = 17;

	constructor(componentReference = null, url, port) {
		if (componentReference) {
			this.componentReference = componentReference;
		}
		this.url = url;
		this.port = port;
		this.socket = null;
		this.reconnectInterval = null;
		this.intervalNumber = 0;
	}

	connect(clientId) {
		console.log('Vamos a crear la conexion al socket: ', `${this.url}:${this.port}/?uid=${clientId}`)
		// Concatenar la URL del servidor WebSocket con la información adicional
		const socketUrl = `${this.url}:${this.port}/?uid=${clientId}`;

		// Crear una nueva instancia de WebSocket
		try {
			this.socket = new WebSocket(socketUrl);
		} catch (e) {
			console.error('Error del WebSocket:', e.message);
		}

		// Manejar eventos de la conexión WebSocket
		this.socket.addEventListener("open", async (event) => {
			store.commit('config/setWsConnectionStatus', {wsConnectionStatus: this.socket.readyState})
			console.log("Conexión establecida:", event);
			clearInterval(this.reconnectInterval);
			if (this.intervalNumber > 0 && this.componentReference) {
				this.intervalNumber = 0;
				if (this.componentReference.updateNotifications && this.componentReference.updateReservations) {
					await this.componentReference.updateNotifications();
					await this.componentReference.updateReservations();
				}
			}

			if (this.componentReference) {
				this.componentReference.colorConnection = 'transparent'
			}
		});

		this.socket.addEventListener("message", async (event) => {
			console.log('recibiendo mensaje en WS', event);
			const message = JSON.parse(event.data);

			const action = message.action;

			switch (action) {
				case WebSocketClient.ACTION_UPDATE_RESERVATION_ORDERS:
					await this.componentReference.updateNotifications();
					return this.componentReference.updateReservations();

				case WebSocketClient.ACTION_SEND_NOTIFICATION_RESERVATION_VALIDATE:
					console.log('reserva validada')
					window.location.href = message.data;
					window.parent.postMessage({event: 'redirectPassFromIframe', data: {url: message.data}}, '*');
					break;

				case WebSocketClient.ACTION_UPDATE_RESERVATION_IN_COPILOT: {
					const updatedReservation = message.data.reservation;
					const updatedReservations = this.componentReference.reservations.map(reservation => {
						if (reservation.id === updatedReservation.id) {
							return updatedReservation;
						} else {
							return reservation;
						}
					});

					store.commit('copilot/setReservations', {reservations: updatedReservations})

					document.dispatchEvent(new Event('buttonTextOrderUpdated'));
					if (message.data.updateNotifications) {
						return this.componentReference.updateNotifications();
					}
					break;
				}

				case WebSocketClient.ACTION_UPDATE_NOTIFICATIONS_IN_COPILOT: {
					await this.componentReference.updateNotifications();
					break;
				}

				case WebSocketClient.ACTION_SEND_NOTIFICATION: {
					console.log('Hemos recibido la notificacion en copilot y es esta: ', message.data);
					store.commit('copilot/setNewNotification', {newNotification: message.data})
					break;
				}

				case WebSocketClient.ACTION_DISABLE_MENU: {
					console.log(message.data, 'desde la accion la reserva actual', this.componentReference.reservationInfo)
					this.componentReference.reservationInfo = {
						...this.componentReference.reservationInfo,
						menuAccessEnabled: message.data.menuAccessEnabled
					};
					console.log('La reserva despues de cambiarla', this.componentReference.reservationInfo)
					break;
				}

				case WebSocketClient.ACTION_ADD_SERVICE_EVENT: {
					const serviceEvent = message.data.serviceEvent;
					const productOrdersIdsToUpdate = message.data.productOrderIdsToUpdate;

					console.log('El servicio event recibido por WS: ', serviceEvent)
					console.log('El productOrdersIdsToUpdate event recibido por WS: ', productOrdersIdsToUpdate)

					store.commit('copilot/updateProductOrdersStatus', {productOrdersIdsToUpdate, status: 3})
					store.commit('copilot/addServiceEvent', {serviceEvent})
					break;
				}

				case WebSocketClient.ACTION_UPDATE_SERVICE_EVENT: {
					const serviceEvent = message.data.serviceEvent;
					const productOrdersIdsToUpdate = message.data.productOrderIdsToUpdate;

					store.commit('copilot/updateProductOrdersStatus', {productOrdersIdsToUpdate, status: 100})
					store.commit('copilot/updateServiceEvent', {serviceEvent})
					break;
				}

				case WebSocketClient.ACTION_UPDATE_A_SINGLE_PRODUCT_ORDER: {
					const productOrderIdToUpdate = message.data.productOrderIdToUpdate;
					const status = message.data.status;

					console.log('El productOrderIdToUpdate event recibido por WS: ', productOrderIdToUpdate)

					store.commit('copilot/updateSingleProductOrder', {productOrderIdToUpdate: productOrderIdToUpdate, status: status})
					break;
				}

				case WebSocketClient.ACTION_ADD_NEW_RESERVATION_IN_COPILOT: {
					const reservation = message.data;

					store.commit('copilot/addReservationToReservations', {reservation})
					break;
				}

				case WebSocketClient.ACTION_UPDATE_MENUS: {
					await store.dispatch('menu/loadMenus');
					break;
				}

				case WebSocketClient.ACTION_UPDATE_RESERVATION_IN_MENU: {
					const updatedReservation = message.data.reservation
					const showModalAssessment = message.data.showModalAssessment
					const userId = this.componentReference.user.id;
					const hasUserRatedReservation = updatedReservation.userReviews.map(review => review.userId).includes(userId);

					this.componentReference.reservationInfo = updatedReservation;

					console.log('updatedReservation',updatedReservation)
					console.log('showModalAssessment',showModalAssessment)
					if(showModalAssessment && !hasUserRatedReservation){
						showOffcanvas('menu-modal-assessment')
					}
					break;
				}

				case WebSocketClient.ACTION_UPDATE_PRODUCT_AVAILABILITY: {
					const productSku = message.data.productSku
					const menuCategoryId = message.data.menuCategoryId
					const newAvailability = message.data.availability

					store.commit('menu/updateProductAvailability', {productSku, menuCategoryId, newAvailability})

					break;
				}

				case WebSocketClient.ACTION_UPDATE_PRODUCT_TOP_CHOICE: {
					const productSku = message.data.productSku
					const topChoice = message.data.topChoice

					store.commit('menu/updateProductTopChoice', {productSku, topChoice})

					break;
				}

				case WebSocketClient.ACTION_UPDATE_MENU_PRODUCT: {
					const product = message.data.product
					store.commit('menu/updateMenuProduct', {product})
					break;
				}
			}

		});

		this.socket.addEventListener("close", (event) => {
			store.commit('config/setWsConnectionStatus', {wsConnectionStatus: this.socket.readyState})
			console.log("Conexión cerrada:", event);

			if (this.reconnectInterval) {
				clearInterval(this.reconnectInterval);
			}
			this.reconnectInterval = setInterval(() => {
				console.log("Intentando reconectar...");
				this.close();
				this.connect(clientId);
				this.intervalNumber++
			}, 2000);
		});


		this.socket.addEventListener("error", (event) => {
			store.commit('config/setWsConnectionStatus', {wsConnectionStatus: this.socket.readyState})
			console.error("Error en la conexión:", event);
		});

		console.log("Estado del socket:", this.socket.readyState);
	}

	// Método para enviar mensajes al servidor
	sendMessage = (message) => {
		if (this.socket && this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(JSON.stringify(message));
		} else {
			console.error("La conexión WebSocket no está abierta.");
		}
	}

	// Método para cerrar la conexión
	close() {
		if (this.socket) {
			store.commit('config/setWsConnectionStatus', {wsConnectionStatus: this.socket.readyState})
			this.socket.close();
			this.socket = null;
		}
	}

	sendPing() {
		console.log('Enviando ping a las ', new Date().toLocaleString())
		this.sendMessage({type: 'ping'})
	}
}
