import store from "@/store";
import {reservationRepository} from "@/service/repository/reservationRepository";
import {walkingService} from "@/service/walkingService";

export class HarvisService {

	constructor() {
		this.executionContext = null;
	}

	async call(instruction){
		this.executionContext = instruction;
		if(this.executionContext){
			switch (this.executionContext.action) {
				case 'walking':
					console.log('Waliking desde harvis: ', this.executionContext)
					return await this.createWalkin();
				case 'activa':
					console.log('Se llama a activar desde harvis');
					return await this.activateTable()
				case 'finaliza':
					console.log('Se llama a desactivar desde harvis');
					return await this.finalizeTable()
				default:
					return 'La acciòn no esta registrada';
			}
		}else{
			return "No se ha detectado ninguna accion"
		}
	}

	async createWalkin(){
		if (this.executionContext.name && this.executionContext.pax){
			let data = {
				name: this.executionContext.name,
				pax: this.executionContext.pax,
				roomId: null
			}

			store.commit("copilot/setToast", {toast:{toastMessage: `Creando Walking para ${this.executionContext.name} de ${this.executionContext.pax}`, showToast:true, idToast: "correctlyToast"}})
			let createWalkInResult = await walkingService.create(data);
			console.log(createWalkInResult);

			return createWalkInResult.result === 0
				? `Se ha creado el walkin correctamente y se le ha asignado la mesa ${createWalkInResult.restooResponse.restooResponse.booking.tables[0].name}`
				: `No se ha podido asignar mesa.`;
		}else {
			return "No se ha podido crear el walking por que falta el nombre del comensal o el número de comensales."
		}
	}

	async activateTable(){
		if(this.executionContext.table){
			console.log(typeof this.executionContext.table)
			const reservations = store.getters['copilot/reservations'];
			console.log('Reservations desde harvis: ', reservations)
			const reservation = reservations.find(reservation =>
				Number(reservation.table) === this.executionContext.table && reservation.status < 0
			);
			if(reservation){
				store.commit("copilot/setToast", {toast:{toastMessage: `Activando la mesa ${this.executionContext.table}`, showToast:true, idToast: "correctlyToast"}});
				const activateResult = await reservationRepository.reactivateReservation(reservation.id);
				return activateResult.result === 0
					? `Se ha ha activado correctamente la mesa ${this.executionContext.table}`
					: `Error activando la mesa.`;
			}else{
				return `La mesa ${this.executionContext.table} no pertenece a ninguna reserva.`
			}
		}else{
			return 'No se ha proporcionado ninguna mesa'
		}
	}

	async finalizeTable(){
		if(this.executionContext.table){
			console.log(typeof this.executionContext.table)
			const reservations = store.getters['copilot/reservations'];
			console.log('Reservations desde harvis: ', reservations)
			const reservation = reservations.find(reservation =>
				Number(reservation.table) === this.executionContext.table && reservation.status === 1
			);
			if(reservation){
				store.commit("copilot/setToast", {toast:{toastMessage: `Finalizando la mesa ${this.executionContext.table}`, showToast:true, idToast: "correctlyToast"}});
				const finishResult = await reservationRepository.finishReservation(reservation.id, false);
				return finishResult.result === 0
					? `Se ha ha desactivado correctamente la mesa ${this.executionContext.table}`
					: `Error desactivando la mesa.`;
			}else{
				return `La mesa ${this.executionContext.table} no pertenece a ninguna reserva.`
			}
		}else{
			return 'No se ha proporcionado ninguna mesa'
		}
	}
}

export const harvisService = new HarvisService();
