<template>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvas_' + product.sku" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header">
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body ">
			<img v-if="showPictureMenu !== null" class="food-img" :src="pictureMenu" alt="">
			<div class="wrapper">
				<h5 class="offcanvas-title " id="offcanvasBottomLabel">{{ product.name }}</h5>

				<div v-if="product.allergens && product.allergens.length > 0" class="food-allergens">
					<img class="allergens-img" v-for="allergen in product.allergens" :key="allergen.id" :src="getImageAllergenUrl(allergen)" :alt="allergen.name">
				</div>
				<div v-else>
					<p>{{ t('menu.checkAllergensWithStaff') }}</p>
				</div>

				<div v-for="(productOption, index) in product.productOptions" :key="`product_option_${product.sku}-${productOption.id}`">
					<hr class="vr-horizontal">
					<div class="extras-container">
						<div class="extras-header">
							<div class="extras-title">
								<span v-if="product.productOptions && product.productOptions.length > 1">{{ index + 1 }} de {{
										product.productOptions.length
									}}: </span>{{ productOption.description }}
							</div>
							<div class="extras-subtitle">
								{{
									productOption.max === 1
										? "Elige una opción"
										: productOption.max === productOption.min ? `Elige hasta ${productOption.max} opciones` : `Elige hasta ${productOption.max} opciones`
								}}
							</div>
						</div>
						<div class="extras-products-list">
							<div class="product-card-client product-card-client-list" v-for="option in productOption.options.filter(option => option.enabled)" :key="option.id">
								<div class="product-card-body">
									<div class="product-card-info">
										<div class="product-card-data">
											<div class="product-card-title">{{ option.name }}</div>
										</div>
									</div>
									<div class="product-card-btns">
										<div class="btns">
											<button v-if="countOptionsInSelectedOptions(option.id) > 0"
													@click="subtractOption({productOptionId: productOption.id,optionId:option.id })"
													class="btn btn-secondary btn-circle">
												<i class="fas" :class="countOptionsInSelectedOptions(option.id) === 1 ? 'fa-trash' : 'fa-minus'"></i>
											</button>
											<div class="product-card-unit">
												{{ countOptionsInSelectedOptions(option.id) }}
											</div>
											<button
												@click="addOption({productOptionId: productOption.id,optionId:option.id })"
												class="btn btn-secondary btn-circle">
												<i class="fas fa-plus"></i>
											</button>
<!--											<button-->
<!--												:disabled="productOption.min === 0 && countSelectedOptionsForProductOptionId(productOption.id) >= highestOptionCount"-->
<!--												@click="addOption({productOptionId: productOption.id,optionId:option.id })"-->
<!--												class="btn btn-secondary btn-circle">-->
<!--												<i class="fas fa-plus"></i>-->
<!--											</button>-->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="offcanvas-footer flex-column">
			<div class="text-muted" v-if="selectedOptions.length && optionsValidationMessage !== true" v-html="optionsValidationMessage"></div>
			<button @click="sendToCart" :disabled="optionsValidationMessage !== true" type="button"
					class="btn btn-primary w-100" :id="'buttonModalAdd' + product.sku">Añadir
			</button>
		</div>
	</div>
</template>

<script>

import {arraysAreEqualIgnoringOrder, hideOffcanvas} from "@/utils";
import store from "@/store";
import {useI18n} from "vue-i18n";

export default {
	name: "MenuProductCardOffCanvasOptionDailyMenu",
	props: {
		product: {
			type: Object,
			required: true
		},
		showPictureMenu: {
			required: true
		},
		pictureMenu: {required: true}
	},
	components: {},
	computed: {
		optionsValidationMessage() {
			if(this.selectedOptions.length){
				return true
			}
			const validProductOptions = this.product.productOptions.filter(option => option.min > 0);
			const productOptionIds = validProductOptions.map(option => option.id);
			const productOptionNames = this.product.productOptions.reduce((acc, option) => {
				acc[option.id] = option.name;
				return acc;
			}, {});

			// Contar la cantidad de opciones seleccionadas por cada 'productOptionId'
			const selectedCount = this.selectedOptions.reduce((acc, item) => {
				acc[item.productOptionId] = (acc[item.productOptionId] || 0) + 1;
				return acc;
			}, {});

			// Encontrar el máximo de selecciones en cualquier opción
			const maxSelectedCount = Math.max(...productOptionIds.map(id => selectedCount[id] || 0));

			// Encontrar las opciones que tienen menos selecciones de las que deberían
			const missingOptions = productOptionIds.filter(id => (selectedCount[id] || 0) < maxSelectedCount);

			// Validar si no se ha seleccionado ninguna opción
			if (maxSelectedCount === 0) {
				return "<b>Falta marcar:</b> todas las opciones.";
			} else if (missingOptions.length > 0) {
				// Construir el mensaje de las opciones que faltan
				const messages = missingOptions.map(id => {
					const countMissing = maxSelectedCount - (selectedCount[id] || 0);
					return `<b>${countMissing}</b> ${productOptionNames[id]}`;
				});

				// Formatear el mensaje correctamente (agregar 'y' entre los últimos elementos)
				const lastOption = messages.pop();
				const formattedMessage = messages.length > 0 ? `${messages.join(", ")} y ${lastOption}` : lastOption;

				return `<b>Falta marcar:</b> ${formattedMessage}`;
			}

			// Si todo está correcto, devolver 'true'
			return true;
		},
		highestOptionCount(){
			const counts = this.selectedOptions.reduce((acc, item) => {
				acc[item.productOptionId] = (acc[item.productOptionId] || 0) + 1;
				return acc;
			}, {});

			return Object.keys(counts).length > 0 ? Math.max(...Object.values(counts)) : 0;
		},

	},
	data() {
		return {
			selectedOptions: []
		}
	},
	setup() {
		const { t } = useI18n();
		return { t };
	},
	methods: {
		getImageAllergenUrl(allergen) {
			return `assets/img/allergies/${allergen.pictureName.toLowerCase()}.png`;
		},
		countOptionsInSelectedOptions(optionId) {
			return this.selectedOptions.filter(item => item.optionId === optionId).length;
		},
		countSelectedOptionsForProductOptionId(productOptionId) {
			return this.selectedOptions.filter(item => item.productOptionId === productOptionId).length;
		},
		addOption(option) {
			console.log(option)
			this.selectedOptions.push(option)
		},
		subtractOption(option) {
			const index = this.selectedOptions.findIndex(item =>
				item.productOptionId === option.productOptionId &&
				item.optionId === option.optionId
			);

			if (index !== -1) {
				this.selectedOptions.splice(index, 1);
			}
		},
		createMenuObjects() {
			console.log("selectedOptions al crear el menu",this.selectedOptions)
			const grouped = this.selectedOptions.reduce((acc, item) => {
				const optionId = item.productOptionId;
				const chosenId = item.optionId;

				if (!acc[optionId]) {
					acc[optionId] = {optionId, chosenIds: []};
				}

				acc[optionId].chosenIds.push(chosenId);

				return acc;
			}, {});

			const transformedArray = Object.values(grouped);

			const minChosenIdsCount = Math.min(...transformedArray.map(option => option.chosenIds.length));

			const chosenArrays = Array.from({length: minChosenIdsCount}, (_, index) => ({
				product: this.product,
				options: transformedArray.map(option => ({
					optionId: option.optionId,
					chosenIds: [option.chosenIds[index]]
				})),
				units: 1
			}));

			return chosenArrays.reduce((acc, currentArray) => {
				const currentChosenIds = currentArray.options.map(option => option.chosenIds[0]);

				const existing = acc.find(item => {
					const existingChosenIds = item.options.map(opt => opt.chosenIds[0]);
					return arraysAreEqualIgnoringOrder(existingChosenIds, currentChosenIds);
				});

				if (existing) {
					existing.units += 1;
				} else {
					acc.push(currentArray);
				}

				return acc;
			}, []);


		},


		createMenuObjectsV2() {
			console.log("selectedOptions al crear el menu", this.selectedOptions);

			// Agrupar opciones por `productOptionId`
			const grouped = this.selectedOptions.reduce((acc, item) => {
				const optionId = item.productOptionId;
				const chosenId = item.optionId;

				if (!acc[optionId]) {
					acc[optionId] = { optionId, chosenIds: [] };
				}

				acc[optionId].chosenIds.push(chosenId);
				return acc;
			}, {});

			// Convertir el objeto agrupado en un array
			const transformedArray = Object.values(grouped);

			// Obtener la cantidad máxima de opciones elegidas
			const maxChosenIdsCount = Math.max(...transformedArray.map(option => option.chosenIds.length));

			// Crear los menús en base a `maxChosenIdsCount`, dejando en blanco si no hay suficiente
			const chosenArrays = Array.from({ length: maxChosenIdsCount }, (_, index) => ({
				product: this.product,
				options: transformedArray.map(option => ({
					optionId: option.optionId,
					chosenIds: [option.chosenIds[index] || null] // Insertar `null` si no hay opción
				})),
				units: 1
			}));

			// Consolidar los menús para combinar aquellos con las mismas opciones
			return chosenArrays.reduce((acc, currentArray) => {
				const currentChosenIds = currentArray.options.map(option => option.chosenIds[0]);

				const existing = acc.find(item => {
					const existingChosenIds = item.options.map(opt => opt.chosenIds[0]);
					return arraysAreEqualIgnoringOrder(existingChosenIds, currentChosenIds);
				});

				if (existing) {
					existing.units += 1;
				} else {
					acc.push(currentArray);
				}

				return acc;
			}, []);
		},

		sendToCart() {
			const menus = this.createMenuObjectsV2();
			menus.forEach(menu => store.commit('menu/addProductToCart', {
				product: menu.product,
				units: menu.units,
				options: menu.options
			}))

			this.selectedOptions = [];
			hideOffcanvas("offcanvas_"+ this.product.sku);
		}
	}
}
</script>

<style scoped>

</style>
