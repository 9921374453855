<template>
	<div id="welcom-reservar" :data-bs-theme="resourceId" class="content-center-justify">

	<HeaderComponent :url-restaurant="weWelcomUrl" :text-title="t('menu.bienvenidos')" :subTitle="businessName" :image-restaurant="resourceId"/>

	<reservation-component></reservation-component>

	<footer-welcom></footer-welcom>
</div>
</template>

<script>

import {mapGetters} from "vuex";

import FooterWelcom from "@/core/FooterWelcom";
import HeaderComponent from "@/core/HeaderComponent";
import ReservationComponent from "@/components/reservationComponent/ReservationComponent";
import {useI18n} from "vue-i18n";

export default {
	name: 'ReserveWelcom',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
	},
	data() {
		return {
		};
	},
	setup() {
		const { t } = useI18n();
		return { t };
	},
	components: {
		'footer-welcom': FooterWelcom,
		ReservationComponent,
		HeaderComponent,
	},
	async beforeMount() {


	},
	async mounted() {


	},
	beforeUnmount() {

	},
	watch: {

	},
	computed: {
		...mapGetters('config' , ['websocketUrl','websocketPort', 'weWelcomUrl']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName','reservationAgent','reservationManagerUrl' ]),
	},
	methods: {

	},
}
</script>
