<template>
	<div class="product-card-employee product-card-employee-secondary product-card-employee-finish rotate-text" @click="openCollapse(reservationId)" :aria-controls="'collapseFinishProducts'+ reservationId">
		<div class="product-card-body">
			<div class="product-card-info">
				<div class="product-card-data">
					<h5 class="product-card-title">Finalizados</h5>
				</div>
				<div class="product-card-footer justify-content-center align-items-center bg-transparent" :id="'arrowFinishKitchen'+ reservationId">
					<i :id="'iconArrowFinishKitchen'+ reservationId" class="fa-solid fa-angle-right"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="collapse collapse-horizontal" :id="'collapseFinishProducts'+ reservationId">
		<div class="reservation-kitchen-content-product-list">
			<CopilotProductCardKitchen
				v-for="product in productsOrder"
				:key="product.productOrderId"
				:product="product"/>
		</div>
	</div>
</template>


<script>
import * as bootstrap from 'bootstrap';

import CopilotProductCardKitchen from "@/components/copilotProductCardKitchen/CopilotProductCardKitchen";

export default {
	name: 'CopilotProductsCollapseKitchen',
	components: {CopilotProductCardKitchen},
	props: {
		reservationId: {
			type: String,
			required: true
		},
		productsOrder: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			isOpenFinishProducts: false,
		}
	},
	watch: {},
	mounted() {
	},
	computed: {},
	methods: {
		toggleProductView() {
			this.isOpenFinishProducts = !this.isOpenFinishProducts;
		},
		openCollapse(elementId) {
			let collapseElement = document.getElementById('collapseFinishProducts' + elementId);
			if (collapseElement) {
				let iconArrow = document.getElementById('iconArrowFinishKitchen' + elementId);
				let containerElement = document.getElementById('arrowFinishKitchen' + elementId);

				let collapseInstance = bootstrap.Collapse.getInstance(collapseElement);
				if (!collapseInstance) {
					collapseInstance = new bootstrap.Collapse(collapseElement, {
						toggle: false
					});
				}
				if (collapseElement.classList.contains('show')) {
					collapseInstance.hide();
					iconArrow.classList.remove('fa-rotate-180');
					containerElement.classList.remove('active');
				} else {
					collapseInstance.show();
					iconArrow.classList.add('fa-rotate-180');
					containerElement.classList.add('active');

					this.$nextTick(() => {
						setTimeout(() => {
							const scrollContainer = collapseElement.closest('.reservation-kitchen-content.scroll-x');
							if (scrollContainer) {
								const firstProductElement = collapseElement.querySelector('.reservation-kitchen-content-product-list > *');
								if (firstProductElement) {
									const offsetLeft = firstProductElement.offsetLeft;
									const extraScroll = 250;
									scrollContainer.scrollTo({
										left: offsetLeft - extraScroll,
										behavior: 'smooth'
									});
								}
							}
						}, 400);
					});
				}
			}
		},
	}


}
</script>

