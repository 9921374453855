<template>
	<div class="modal fade" id="modalMenuRequestAction" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered ">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" id="request-ticket">
						Solicitar la Cuenta
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>

				<div class="modal-body">
					<ul >
						<li v-for="product in productsCart" :key="'productRequestAccount' + reservationInfo.id + product.sku">
							<div class="border-bottom d-flex justify-content-between">
								<div class="row">
									<div class="col-9">
										{{ product.name }}
										<br>
										<span v-for="(chosenOption,index) in product.chosenOptions" class="card-extras text-start" :key="'optionRequestAccount' + reservationInfo.id + chosenOption.id">
											{{ (product.productOptions.flatMap(option => option.options).find(opt => opt.id === chosenOption)?.name) }}
											<span v-if="index !== product.chosenOptions.length - 1">, </span>
										</span>
									</div>
									<div class="col-3">
										<b>{{ product.cartItemCount }} ud</b>
									</div>
								</div>
							</div>
						</li>
					</ul>

					<div class="text-center text-md-start">
						Listo para finalizar? Simplemente haz clic en el botón de
						<b>'Solicitar cuenta'</b> y nos ocuparemos del resto.
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton content-btn-text="Cancelar"/>
					<button class="btn btn-primary " @click="handleRequestTicket">
						<span v-html="menuButtonContentAccount"></span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import DismissButton from "@/core/DismissButton";
import {hideAllModals, hideAllOffcanvas} from "@/utils";
import {mapGetters} from "vuex";
import store from "@/store";

export default {
	name: 'menuModalRequestAccount',
	data() {
		return {
			menuButtonContentAccount: 'Solicitar cuenta',
		};
	},
	props: {
		reservationInfo: {
			type: Object,
			required: true
		}
	},
	components:{DismissButton},
	computed:{
		...mapGetters('menu', ['menusVenue', 'productsCart']),
		...mapGetters('user', ['user']),
	},
	mounted() {

	},
	methods: {
		async handleRequestTicket() {
			this.menuButtonContentAccount = 'Solicitando...'
			this.isSpinnerIconLoading = true;
			hideAllModals();

			const resultTicket = await store.dispatch('menu/requestAccount', {reservationInfo: this.reservationInfo, user: this.user})

			if (resultTicket.result === 0) {
				hideAllModals();
				hideAllOffcanvas();
				this.messageCart = {clientName: this.user.name, products: null};

				this.menuButtonContentAccount = 'Solicitado...';
				this.isRequestAccount = true;

				this.menuButtonContentAccount = 'Solicitar cuenta';
				this.isSpinnerIconLoading = false;
			}
		},
	}
}
</script>
