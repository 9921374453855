<template>
	<div class="modal fade" :id="idModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title" id="share-menu-qr">
						<i class="fas fa-exclamation-circle"></i> {{ title }}</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div v-if="isAccountRequester" class="container">
						<p class="text-center text-md-start">
							<b>¡Gracias por valorar nuestro servicio!</b>
						</p>

						<div class="mb-3" v-if="userReview && userReview.score > 4">
							<p class="text-center text-md-start">
								te invitamos a compartir tu opinión en Google.
							</p>

							<button @click="openGoogle" class="btn btn-primary w-100">
								<GoogleIcon/>
								Valorar en google
							</button>
						</div>
						<div class="text-center text-md-start">
							<b>Hazte con tu WePass.</b> Disfruta sin esperas, pide desde tu móvil y personaliza la carta a tu manera. ¡Tu experiencia más rápida y cómoda está a un clic!
						</div>
						<div class="text-center text-md-start">
							<b>¡Esperamos verte pronto nuevamente!</b>
						</div>
					</div>
					<div v-else class="container">
						<span v-if="isPaymentOnlineSuccess">
							<SuccessIcon/>
						</span>

						<p class="text-center text-md-start">
							¡Gracias <span class="fw-bold" v-if="user">{{ user.name }}</span>
							<span v-if="!isPaymentOnlineSuccess"> por valorar nuestro servicio!</span>
							<span v-if="isPaymentOnlineSuccess"> tu pago se ha realizado con éxito!</span>
							<span v-if="userReview && userReview.score > 4">Nos alegra saber que disfrutaste de tu experiencia.</span>
						</p>

						<div class="mb-3" v-if="userReview && userReview.score > 4">
							<p class="text-center text-md-start">
								te invitamos a compartir tu opinión en Google.
							</p>

							<button @click="openGoogle" class="btn btn-primary w-100">
								<GoogleIcon/>
								Valorar en google
							</button>
						</div>

						<p class="text-center text-md-start">
							<b>Hazte con tu WePass.</b> ¡Tu experiencia más rápida y cómoda está a solo un clic!
						</p>

						<p class="text-muted" v-if="!isPaymentOnlineSuccess">
							En breve, nuestro personal te atenderá.
						</p>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DismissButton from "@/core/DismissButton";
import {mapGetters} from "vuex";
import GoogleIcon from "@/core/icons/googleIcon";
import SuccessIcon from "@/core/icons/SuccessIcon";

export default {
	name: "MenuModalPaymentResult",
	inject: {
		menuRepository: 'menuRepository',
		reservationRepository: 'reservationRepository',
	},
	props: {
		reservationInfo: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			idModal: 'modal-payment-result',
		}
	},
	mounted() {
	},
	components: {SuccessIcon, GoogleIcon, DismissButton},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['reviewGoogleMapsUrl', 'paymentConfig']),
		...mapGetters('menu', ['isAccountRequester']),

		title() {
			if (this.isPaymentOnlineSuccess && this.isActiveReservation && this.isAccountRequester) {
				return 'Pago realizado';
			} else if (!this.isPaymentOnlineSuccess && this.isActiveReservation && this.isAccountRequester) {
				return 'Cuenta pedida';
			} else {
				return 'Valoración enviada';
			}
		},
		userReview() {
			return this.reservationInfo.userReviews?.find(review => this.user.id === review.userId);
		},

		isPaymentOnlineSuccess() {
			return this.reservationInfo && this.reservationInfo.payments && this.reservationInfo.payments.some(payment => payment.type === 'Online');
		},

		isActiveReservation() {
			return this.reservationInfo && !this.reservationInfo.finishedDateTime && this.reservationInfo.status === 1 && this.reservationInfo.menuAccessEnabled === 1
		},
	},
}
</script>
