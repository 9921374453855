import {sendRequestJson, sendRequest} from '@/utils'

export class ReservationRepository {

	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('ReservationRepository con urlBase: ', this.urlBase)
	}

	async find(reservationId) {
		const data = {reservationId}
		const url = this.urlBase + "/world/api/reservation/reservationinfo"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo la informacion de la reserva.");
		}
	}

	async create(data) {
		const url = this.urlBase + "/world/reservation/register"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal creando la reserva.");
		}
	}

	async update({client, data}) {
		const url = `https://${client}.wewelcom.io` + "/world/api/reservation/updatereservation";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando la reserva.");
		}
	}

	async getAvailableRooms() {
		const url = this.urlBase + "/world/reservation/availablerooms";
		try {
			return await sendRequest({data: {}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo las aviableRooms.");
		}
	}

	async checkAvailability(data) {
		const url = this.urlBase + "/world/reservation/checkavailability";
		try {
			return await sendRequest({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal comprobando la disponibilidad.");
		}
	}

	async cancelReservation(reservationId) {
		const url = this.urlBase + "/world/reservation/canceldirect";
		const data = {
			"reservationId": reservationId,
		};
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando reserva por email");
		}
	}

	async cancelAssistanceReservation(reservationId, userId) {
		const url = this.urlBase + "/world/reservation/removeinvitee";
		const data = {
			"reservationId": reservationId,
			"userId": userId,
		};
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando la cancelacion de la asistencia");
		}
	}

	async addInvitedUser(reservationWid, userId) {
		const url = this.urlBase + "/world/api/reservation/adduser"
		const data ={
			reservationWid, userId
		}
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal aceptando la reserva por invitacion.");
		}
	}

	async addUnknownInvitedUser(reservationWid, hostCode, invitedUserName) {
		const url = this.urlBase + "/world/api/reservation/addguest"
		try {
			let data = {
				createGuest: true,
				reservationWid,
				hostCode,
				invitedUserName
			}
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal aceptando la reserva por invitacion de usuario desconocido.");
		}
	}

	async getReservationsByDay(date) {

		const url = this.urlBase + "/world/api/reservation/getbyday"
		try {
			const data = {date}
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal pidiendo la informacion de reservas");
		}
	}

	async getTakeAway() {
		const url = this.urlBase + "/world/account/gettakeaway"
		try {
			return await sendRequestJson({data:{}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal en el getTakeAway");
		}
	}

	async finishReservation(reservationWid, noShow) {
		const url = this.urlBase + "/world/api/reservation/finish";

		let data = {
			reservationWid,
			noShow
		}

		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal finalizando la reserva.");
		}
	}

	async reactivateReservation(reservationWid) {
		const url = this.urlBase + "/world/api/reservation/reactivate";

		let data = {
			reservationWid,
		}

		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal reactivate la reserva.");
		}
	}

	async updatePax(data) {
		const url = this.urlBase + "/world/api/reservation/updatepax"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal modificando los pax");
		}
	}

	async createPayment(data) {
		const url = this.urlBase + "/world/api/payment/create";
		try {
			return await sendRequestJson({data, url, method: 'POST'})
		} catch (e) {
			console.log("Algo ha salido mal en createPayment")
		}
	}
}

export const reservationRepository = new ReservationRepository();
