<div v-if="reservationAgent === 'restoo'">
	<widget-reserve></widget-reserve>
</div>
<div v-else class="container">
	<section id="booking">
		<div class="row form-row-content">
			<div class="col-md-10 col-lg-10">
				<h2>
					Haz tu reserva
				</h2>
			</div>
		</div>

		<form>
			<!--Inputs de el dia de reserva y los comensales-->
			<div class="row form-row-content row-column-gap">
				<div class="col-12 col-md-5 col-lg-5 mb-0">
					<DatePicker :store-module="'reservationForm'"/>
				</div>

				<div class="col-6 col-md-5 col-lg-5">
					<div class="input-group"><span class="input-group-text"><i class="fas fa-user-plus"></i></span>
						<input id="input-pax" v-model="reservationPax" type="number" class="form-control"
							   aria-label="" placeholder="Comensales:">
					</div>
				</div>


				<div class="col-6 col-md-5 col-lg-5">
					<TimePicker/>
				</div>

				<div v-if="isVisibleSelectRooms" class="col-12 col-md-5 col-lg-5 container-select" :class="{'disabled':!isVisibleRoomsSelect}" disabled="!isVisibleRoomsSelect">
<!--					<i class="fas fa-map-pin icon-select"></i>-->
					<select v-model="reservationRoom" class="form-select" aria-label="">
						<option value="-1" selected disabled>Zona:</option>
						<option v-for="room in availableRooms" :key="room.room_id" :value="room.room_id">
							{{ room.name }}
						</option>
					</select>
				</div>

			</div>

			<!--Alerta de errpr No tenemos sitio para el dia y las personas seleccionadas-->
			<div :class="{ 'hidden': !isCannotBeReserved}" class="row form-row-content">
				<div :class="classResponsiveAlert">
					<div id="reservationResponseCollapse" class="alert alert-danger" role="alert">
						No tenemos sitio para el día y las personas seleccionas.
					</div>
				</div>
			</div>

			<!--Alerta de errpr por favor, selecciona el día. Por favor, indica cuántos asistirán. Por favor, indica la hora.-->
			<div :class="{ 'hidden': !showErrorMessageDate }" class="row form-row-content">
				<div :class="classResponsiveAlert" role="alert">
					<div id="reservationResponseCollapse" class="alert alert-danger">
						<div v-if="!reservationDate">
							Por favor, selecciona el día.
						</div>
						<div v-else-if="!reservationPax">
							Por favor, indica cuántos asistirán.
						</div>
						<div v-else-if="!timePicker.get('select')">
							Por favor, indica la hora.
						</div>
					</div>
				</div>
			</div>

			<!--Input Ingresa email-->
			<div v-if="currentStep > 1" class="row form-row-content">
				<div class="col-12 col-md-5 col-lg-5">
					<label class="form-label">Introduce tu email.</label>
					<div class="input-group">
								<span class="input-group-text prepend-icon" id="reservationWelKeyMailStepIcon"><i
									class="pl-1 fa fa-envelope"></i></span>
						<input :ref="`reservation-mail-wel-key`" id="reservation-mail-wel-key"
							   v-model="reservationMailWelkey" ref="stepTwo"
							   type="text" class="form-control" placeholder="Email">
					</div>
				</div>
				<div class="col-md-5 col-lg-5">
				</div>
			</div>

			<!--Alerta de error email invalido-->
			<div :class="{ 'hidden': !showErrorMessageWelKey }" class="row form-row-content">
				<div :class="classResponsiveAlert">
					<div id="reservationResponseCollapse" class="alert alert-danger">
						¡Introduce un email válido!
					</div>
				</div>
			</div>



			<template v-if="currentStep >= 3 || isVisiblePhoneInput ">
				<!--Alerta de información-->
				<div class="row form-row-content">
					<div :class="classResponsiveAlert">
						<div id="reservationResponseCollapse" class="alert alert-info" role="alert">
							Por favor, necesitamos estos datos para continuar:
						</div>
					</div>
				</div>

				<!--Inputs de Nombre y Primero Apellido-->
				<div class="row form-row-content">
					<div v-if="currentStep === 4" class="col-12 col-md-5 col-lg-5" id="signupNameSection">
						<div class="input-group">
							<span class="input-group-text prepend-icon"><i class="fa fa-user"></i></span>
							<input v-model="signupName" :ref="`signup-name`" id="signup-name" name="name" type="text"
								   class="form-control" placeholder="Nombre:" required="">
						</div>
					</div>

					<div v-if="isVisibleSurnameInput" id="signupSurNameSection" class="col-12 col-md-5 col-lg-5">
						<div class="input-group">
							<span class="input-group-text prepend-icon"><i class="fa fa-user"></i></span>
							<input v-model="signupSurname" :ref="`signup-surname`" id="signup-surname" name="last-name"
								   type="text"
								   class="form-control" placeholder="Primer Apellido:" required="">
						</div>
					</div>
				</div>

				<!--Input de numero de teléfono-->
				<div class="row form-row-content">
					<div class="col-12 col-md-5 col-lg-5" v-if="isVisiblePhoneInput">
						<vue-tel-input @input="onInputPhone" :input-id="inputId" :show-country-code="true" label="Email"
									   placeholder="Teléfono:"/>
					</div>
					<div class="col-6 col-md-5 col-lg-5">
					</div>
				</div>

				<!--Inputs check-->
				<div class="row form-row-content" v-if="false">
					<div class="col-12 col-md-10 col-lg-10" >
						<div class="row">
							<div class="col-lg-auto">
								<div class="form-check">
									<input class="form-check-input" id="signup-gender-male" name="gender" type="radio"
										   value="1"
										   v-model="signupGender"><label class="form-check-label"
																		 for="signup-gender-male">Hombre</label>
								</div>
							</div>

							<div class="col-lg-auto">
								<div class="form-check">
									<input class="form-check-input" id="signup-gender-female" name="gender" type="radio"
										   value="2" v-model="signupGender"><label class="form-check-label"
																				   for="signup-gender-female">Mujer</label>
								</div>
							</div>

							<div class="col-lg-auto">
								<div class="form-check">
									<input class="form-check-input" id="signup-gender-unknown" name="gender"
										   type="radio"
										   value="3" v-model="signupGender">
									<label class="form-check-label" for="signup-gender-unknown"> Otro</label>
								</div>
							</div>

							<div class="col-lg-auto">
								<div class="form-check">
									<input class="form-check-input" id="signup-gender-null" name="gender" type="radio"
										   value="4" v-model="signupGender">
									<label class="form-check-label" for="signup-gender-null"> Prefiero no
										contestar</label>
								</div>
							</div>

						</div>
					</div>
				</div>
				<!--Input check Términos-->

				<div class="row form-row-content" v-if="!isCopilotRoute">
					<div class="col-12 col-md-6 col-lg-10" v-if="isVisibleTermsInput">
						<div id="signupTermsSection" class="form-check">
							<input v-model="signupTerms" id="signup-terms" class="form-check-input" type="checkbox"
								   value="signup-terms">
							<label class="form-check-label" for="signup-terms">Acepto la política de
								privacidad</label>
							<a data-bs-toggle="modal" data-bs-target="#privacy-terms-modal"
							   class="f-no-utm terms link" href="#termsPrivacy">(ver)</a>
						</div>
					</div>
				</div>

				<div :class="{ 'hidden': !showErrorMessageForm }" class="row form-row-content">
					<div :class="classResponsiveAlert">
						<div id="reservationResponseCollapse"
							 class="alert alert-danger" role="alert">
							{{ errorMessage }}
						</div>
					</div>
				</div>
			</template>

			<!--Reserva solapada-->
			<template v-if="isOverlappingReservation" >
				<div class="row form-row-content">
					<div :class="classResponsiveAlert">
						<div v-if="this.source === 'copilot'" id="overlappingReservationCopilot" class="alert alert-danger" role="alert">
							El cliente ya tiene una reserva para este dia.
						</div>
						<div v-else id="overlappingReservation" class="alert alert-danger" role="alert">
							Ups, parece que ya tienes una reserva para este dia.
						</div>
					</div>
				</div>

				<div class="row form-row-content">
					<div class="col-12 col-md-5 col-lg-5">
						<a v-if="this.source !== 'copilot'" :href="`${wePassUrl}?uid=${userReservationUid}&action=2&date=${selectedDate}&time=${selectedTime}`" class="btn btn-primary w-100" >
							Ir a modificar la reserva</a>
					</div>
				</div>
			</template>

			<div class="form-row-content row">
				<div class="col-12 col-md-5 col-lg-5">
					<button :disabled="reserveButtonDisabled" class="btn btn-primary w-100" @click="submitReservation">
					<span v-html="reserveButtonContent"></span><SpinnerIcon v-if="isSpinnerIconLoading"/></button>
				</div>
			</div>

		</form>
	</section>
</div>


<modal-check-inbox :mail-confirm-save="mailConfirmSave"></modal-check-inbox>
<modal-privacy></modal-privacy>

