<template>
	<div class="input-group">
		<input v-if="isNewOption" class="form-control w-auto" v-model="localOption.name" type="text" placeholder="Nombre del plato (vacío para borrar)">
		<input v-else class="form-control w-auto disabled" :placeholder="localOption.name">
		<input  class="form-control" v-model="localOption.priceSupplement" type="number" placeholder="€">
		<div class="input-group-text">
			<input class="form-check-input mt-0" type="checkbox" v-model="isChecked" placeholder="€">
		</div>
	</div>
</template>
<script>

export default {
	name: 'CopilotDailyMenuOption',
	components: {

	},
	inject: {

	},
	props: {
		option: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			selectedMenuProductSku: null,
			localOption: null
		}
	},
	computed: {
		isChecked: {
			get() {
				return this.localOption.enabled === 1;
			},
			set(value) {
				this.localOption.enabled = value ? 1 : 0;
			}
		},
		isNewOption(){
			return this.option.isNewOption || false
		}
	},
	beforeMount() {
		this.localOption = {...this.option}
	},
	mounted() {

	},
	beforeUnmount() {

	},
	watch:{
		localOption: {
			handler(newVal) {
				this.$emit('update-option', newVal);
			},
			deep: true
		},
		option: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== JSON.stringify(this.localOption)) {
					this.localOption = { ...newVal };
				}
			},
			deep: true
		}
	},
	methods: {

	}

}
</script>
