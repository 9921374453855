<template>
	<div :class="['input-group', { 'hidden-input': !isSearchExpanded }]" @click="openModal">
		<span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
		<input
			ref="outsideSearchInput"
			type="text"
			class="form-control"
			placeholder="Escribe aquí para buscar tu producto"
			v-model="searchQuery"
			@input="search"
		>
		<span v-if="isSearchExpanded" class="input-group-text" @click="handleInputAction" style="cursor: pointer;">
			<i class="fa-solid fa-circle-xmark"></i>
		</span>
	</div>

	<!-- Modal compartir por qr -->
	<div class="modal fade" id="modal-search-products" tabindex="-1" aria-hidden="true" data-bs-backdrop="false" style="width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 3000;">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered">
			<div class="modal-content" style="z-index: 2000;">
				<div class="modal-header">
					<div class="input-group" style="position: sticky; top: 0; z-index: 1000;">
						<span class="input-group-text"><i class="fas fa-search"></i></span>
						<input
							ref="modalSearchInput"
							type="text"
							class="form-control"
							:placeholder="t('menu.searchProductTypeHere')"
							v-model="searchQuery"
							@input="search"
						>
						<span v-if="isSearchExpanded" class="input-group-text" @click="handleInputAction" style="cursor: pointer;">
							<i class="fa-solid fa-circle-xmark"></i>
						</span>
					</div>
				</div>
				<div class="modal-body" style="position: relative;">

					<p v-if="filteredProducts.length === 0" class="text-muted">{{ t('menu.searchMinChar') }}</p>

					<template v-if="filteredProducts.length > 0" >
						<div :class="isCopilotRoute ? 'row':'list-products'" >
							<menu-product-card
								v-for="product in filteredProducts" :key="product.sku"
								:product="product"
								:is-copilot-route="isCopilotRoute"
								:product-unit="productUnit"
								:is-valid-reservation="isValidReservation"
								@add-product="addProductToCart"
								@add-unit="addUnit"
								@subtract-unit="subtractUnit"
							></menu-product-card>
						</div>
					</template>

					<div v-if="!filteredProducts.length && searchQuery.length" class="d-flex justify-content-center flex-column text-center h-100">
						<iframe src="https://lottie.host/embed/77010824-aaa0-40db-be98-cab514d74be3/8kp4cN9HsQ.json"></iframe>
						<p><i18n-t keypath="menu.noResults">
							<template #searchQuery>
								<span class="h3 fw-bold">"{{ searchQuery }}"</span>
							</template>
						</i18n-t></p>
					</div>

					<div v-if="filteredProducts.length === 0" class="d-flex justify-content-center flex-column text-center h-100">
						<iframe src="https://lottie.host/embed/67ac09e4-320b-4279-a559-ae3f0bcb5d87/exjiMHFfFZ.json"></iframe>
						<h3>{{ t('menu.searchingSpecific') }}</h3>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton :is-modal="true" :on-click="dismissAndChangeStatus"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MenuProductCard from "@/components/menuProductCard/MenuProductCard";
import DismissButton from "@/core/DismissButton";
import * as bootstrap from 'bootstrap';
import {useI18n} from "vue-i18n";

export default {
	name: 'MenuSearcher',
	components: {DismissButton, MenuProductCard},
	emits: ['add-product', 'add-unit', 'subtract-unit', 'filter-menus', 'search-expand'],
	props: {
		menus: {
			type: Array,
			required: true,
			validator(value) {
				return value.every(item => typeof item === 'object' && item !== null);
			}
		},
		isValidReservation: {
			type: Boolean,
			required: false
		},
		reservationPromotion: {
			type: [Object, null],
			required: false
		},
		isCopilotRoute: {
			type: Boolean,
			required: true
		},
		productUnit: {
			type: Object,
		},
		smallButtons: {
			type: Boolean
		}
	},
	data() {
		return {
			searchQuery: '',
			filteredProducts: [],
			isSearchQueryContent: false,
			isSearchExpanded: false,
			isClosingFromButton: false,
			searchTimeout: null
		};
	},
	setup() {
		const { t } = useI18n();
		return { t };
	},
	computed: {
		...mapGetters('config', ['weWelcomUrl']),
		...mapGetters('venue', ['businessName', 'urlVenue', 'resourceId']),
		products() {
			return this.menus
				.flatMap(item => item.menuCategories)
				.flatMap(menuCategory => menuCategory.products);
		}
	},
	methods: {
		...mapActions('menu',['changeIsOpenModalSearch']),
		dismissAndChangeStatus() {
			this.searchQuery = '';
			this.filteredProducts = [];
			this.changeIsOpenModalSearch(false);
		},
		toggleSearchExpand() {
			if (this.isSearchExpanded && (this.searchQuery.length > 0 || document.activeElement === this.$refs.outsideSearchInput)) {
				return;
			}

			if (this.isClosingFromButton) {
				this.isClosingFromButton = false;
				return;
			}

			this.isSearchExpanded = !this.isSearchExpanded;
			this.emitSearchExpand(this.isSearchExpanded);

			if (this.isSearchExpanded) {
				this.$nextTick(() => {
					if (this.$refs.outsideSearchInput) {
						this.$refs.outsideSearchInput.focus();
					}
				});
			}
		},
		emitSearchExpand(isExpanded) {
			this.$emit('search-expand', isExpanded);
		},
		openModal() {
			// Abre el modal y realiza foco al input interno
			const modalElement = new bootstrap.Modal(document.getElementById('modal-search-products'));
			modalElement.show();
			this.changeIsOpenModalSearch(true)
			setTimeout(() => {
				if (this.$refs.modalSearchInput) {
					this.$refs.modalSearchInput.focus();
				}
			}, 500);
		},
		search() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				if (this.searchQuery.length > 1) {
					this.filteredProducts = this.filterMenus();
					// this.highlightText(this.searchQuery);
					this.isSearchQueryContent = true;
				} else {
					this.clearSearchResults();
				}
			}, 300); // 300ms debounce
		},
		clearSearchResults() {
			this.filteredProducts = [];
			this.$emit('filter-menus', null);
			this.isSearchQueryContent = false;
		},
		highlightText(query) {
			this.clearHighlights();

			const normalizedQuery = this.normalizeText(query);
			const regex = new RegExp(`(${normalizedQuery})`, 'gi');

			document.querySelectorAll('.product-card-client .product-card-title, .product-card-client .product-card-text, .product-card-client .food-desc').forEach(element => {
				element.innerHTML = this.normalizeText(element.textContent).replace(regex, '<mark>$1</mark>');
			});
		},
		clearHighlights() {
			document.querySelectorAll('.product-card-client mark').forEach(mark => {
				const parent = mark.parentNode;
				parent.replaceChild(document.createTextNode(mark.textContent), mark);
				parent.normalize();
			});
		},
		normalizeText(text) {
			return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
		},
		filterMenus() {
			const query = this.normalizeText(this.searchQuery);
			const filteredMenus = this.menus.map(category => this.filterCategory(category, query)).filter(Boolean);

			this.$emit('filter-menus', filteredMenus);
			this.$nextTick(() => {
				this.scrollToFirstProduct(filteredMenus);
			});

			return filteredMenus.flatMap(category => category.menuCategories.flatMap(subCategory => subCategory.products));
		},
		filterCategory(category, query) {
			const filteredSubCategories = category.menuCategories
				.map(subCategory => this.filterSubCategory(subCategory, query))
				.filter(Boolean);

			return filteredSubCategories.length > 0 ? { ...category, menuCategories: filteredSubCategories } : null;
		},
		filterSubCategory(subCategory, query) {
			const filteredProducts = subCategory.products
				.map(product => this.filterProduct(product, query))
				.filter(Boolean);

			return filteredProducts.length > 0 ? { ...subCategory, products: filteredProducts } : null;
		},
		filterProduct(product, query) {
			const regex = new RegExp(`(${query})`, 'gi');
			const highlightedName = product.name.replace(regex, '<mark>$1</mark>');
			const highlightedDescription = product.description ? product.description.replace(regex, '<mark>$1</mark>') : '';

			return this.normalizeText(product.name).includes(query) ||
			(product.description && this.normalizeText(product.description).includes(query))
				? { ...product, highlightedName, highlightedDescription }
				: null;
		},
		scrollToFirstProduct(menus) {
			if (menus.length > 0) {
				const firstProduct = menus[0];

				let productElement;

				if (firstProduct.menuTypeName === 'Bebida') {
					productElement = document.getElementById(`drink${firstProduct.menuTypeName}`);
				} else {
					productElement = document.getElementById(`food${firstProduct.menuTypeName}`);
				}

				if (productElement) {
					const elementPosition = productElement.getBoundingClientRect().top + window.scrollY;
					window.scrollTo({
						top: elementPosition,
						behavior: 'smooth'
					});
				}
			}
		},
		addProductToCart(product, units, options) {
			this.$emit('add-product', product, 1, options);
		},
		addUnit(product) {
			this.$emit('add-product', product);
		},
		subtractUnit(product) {
			this.$emit('subtract-unit', this.matchingProductUnits(product));
		},
		matchingProductUnits(product) {
			return this.productUnit.find(unit => unit.sku === product.sku) || { cartItemCount: 0 };
		}
	}
}
</script>

<style scoped>
li {
	list-style: none;
	margin: 0;
	padding: 0;
}
</style>
