<template>
	<div id="modal-sit-in" class="offcanvas offcanvas-bottom fade" tabindex="-1">
		<div class="offcanvas-header mb-0">
			<div id="modal-sit-in-label" class="offcanvas-title h4"><SittingIcon /> {{ t('copilot.offcanvasSitin.title', { sitin: t('copilot.sitin') }) }}</div>
			<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
		</div>
		<div class="offcanvas-body p-3">
			<form>
				<copilot-sitting-select-table @update-selected-tables="handleSelectedTablesUpdate" ref="sittingSelectTable"></copilot-sitting-select-table>

				<div class="input-group mt-3">
					<span class="input-group-text"><i class="fas fa-user-plus"></i></span>
					<input id="input-pax" v-model="walkInPax" aria-label="" class="form-control"  :placeholder="`${t('copilot.offcanvasWalkin.placeHolderPax')}`" type="number">
				</div>

				<div v-if="showErrorMessageWalkIn" id="walk-in-response-collapse" class="alert alert-danger" role="alert">
					{{ errorMessageWalkIn }}
				</div>

				<div class="row mt-3 justify-content-center">
					<div class="col-12 col-md-auto">
						<button :disabled="walkInButtonDisabled || isLoadingBtnSitIn" class="btn btn-primary w-100" @click="handleNewWalkinButton">
							<span v-if="isCreateSitin">{{t('copilot.offcanvasSitin.createSitinBtn', { sitin: t('copilot.sitin')})}}</span><span v-else><SpinnerIcon/></span>
						</button>
					</div>
				</div>
			</form>
		</div>
		<div class="offcanvas-footer">
			<DismissButton :is-modal="false" :on-click="closeModal"/>
		</div>
	</div>
</template>
<script>


import DismissButton from "@/core/DismissButton";
import {hideAllModals, hideAllOffcanvas, hideOffcanvas} from "@/utils";
import SittingIcon from "@/core/icons/SittingIcon";
import CopilotSittingSelectTable from "@/components/copilotSittingSelectTable/CopilotSittingSelectTable";
import store from "@/store";
import {walkingService} from "@/service/walkingService";
import {useI18n} from "vue-i18n";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'CopilotSitting',
	components: {SpinnerIcon, CopilotSittingSelectTable, DismissButton, SittingIcon},
	inject: {
		reservationRepository: 'reservationRepository',
		copilotRepository: 'copilotRepository',
	},
	props: {},
	data() {
		return {
			walkInPax: null,
			isCreateSitin: true,
			isLoadingBtnSitIn:false,
			showErrorMessageWalkIn: false,
			errorMessageWalkIn: null,
			selectedTables: []
		};
	},
	computed: {
		walkInButtonDisabled(){
			return !this.selectedTables.length || !this.walkInPax
		},
	},
	setup() {
		const { t } = useI18n();

		return { t };
	},
	methods: {
		closeModal(){
			hideAllModals();
		},
		handleSelectedTablesUpdate(newSelectedTables) {
			this.selectedTables = newSelectedTables;
		},
		async handleNewWalkinButton(){
			event.preventDefault()
			this.isLoadingBtnSitIn = true;
			this.isCreateSitin = false
			const tables = this.selectedTables.map(table => table.table_id)
			const data = {pax: this.walkInPax, tables};
			console.log(data);


			let resultWalkin = await walkingService.create(data);
			console.log("El resultado de crear el walkin", resultWalkin);

			if(resultWalkin.result === 0){
				this.isLoadingBtnSitIn = false;
				store.commit("copilot/setToast", {toast:{toastMessage: "Se ha creado el sitting correctamente", showToast:true, idToast: "correctlyToast"}});
				this.$refs.sittingSelectTable.clearSelectedTables();
				this.walkInPax = null;
				hideOffcanvas('modal-sit-in');
			}else{
				this.isLoadingBtnSitIn = false;
				store.commit("copilot/setToast", {toast:{toastMessage: 'No se ha podido crear el sit in.', showToast:true, idToast: "incorrectlyToast"}});
				hideAllModals();
				hideAllOffcanvas();
			}
			this.isCreateSitin = true;
		},
	}

}
</script>
