<template>
	<nav id="navbar-scroll-categories" class="navbar-container-scroll-categories gap-3 justify-content-center" :class="{'position-absolute':isCopilotRoute}">
		<menu-searcher
			v-if="menus.length"
			:menus="menus"
			:is-copilot-route="isCopilotRoute"
			:reservation-promotion="reservationPromotion"
			:is-Valid-Reservation="isValidReservation"
			:product-unit="productUnit"
			:small-buttons="true"
			@subtract-unit="$emit('subtract-unit', $event)"
			@add-product="$emit('add-product', $event)"
			@add-unit="$emit('add-unit', $event)"
			@filter-menus="$emit('filter-menus', $event)"
			@search-expand="handleSearchToggle"
		></menu-searcher>
		<!--		<menu-searcher v-if="menus.length" :menus="menus" :is-copilot-route="isCopilotRoute" :reservation-promotion="reservationPromotion" :is-Valid-Reservation="isValidReservation" @subtract-unit="subtractUnit" @add-product="addProductToCart" @add-unit="addUnit" @filter-menus="filterMenuCategoriesByProducts" :product-unit="productsCart"></menu-searcher>-->

		<template v-for="(menu, index) in activeMenu" :key="menu.id">
			<a
				class="link-menu btn btn-light"
				:class="{ 'active': menu.id + menu.name === activeCategory, 'hidden-text': isSearchExpanded || (index === 0 && activeCategory),'link-position-end hidden-text': isSearchExpanded || (index === 1 && isFirstActive)}"
				@click.prevent="scrollTo(menu.id + menu.name)"
				:id="menu.id + '-' + menu.name"
				style="flex-shrink: 0"
			>
				<i :class="getIconClass(menu.menuTypeName)"></i>
				<span>{{ translateMenuName(menu.menuTypeName) }}</span>
			</a>
			<div v-if="expandedCategory === menu.id + menu.name && !isSearchExpanded" :class="{'overflow-hidden': true, 'flex-grow-1': true, 'd-flex': true}">
				<div class="scroll-x p-0 d-flex" :ref="'productContainer-' + menu.id ">
					<NavbarScrollCategoriesItem
						v-for="category in menu.menuCategories"
						:key="category.id"
						:category="category"
						:scroll-to="scrollTo"
						:is-active-category="isActiveCategory"
					/>
				</div>
			</div>
		</template>

	</nav>
</template>

<script>

import MenuSearcher from "@/components/menuSearcher/MenuSearcher";
import NavbarScrollCategoriesItem from "@/components/navbarScrollCategories/NavbarScrollCategoriesItem";
import {useI18n} from "vue-i18n";

export default {
	name: "NavbarScrollCategories",
	components: {NavbarScrollCategoriesItem, MenuSearcher,},
	emits: ['add-product', 'add-unit', 'subtract-unit', 'filter-menus'],
	props: {
		activeMenu: {
			type: Array,
			default: () => []
		},
		isCopilotRoute: {
			type: null,
		},
		isValidReservation: {
			type: null,
		},
		reservationPromotion: {
			type: null,
		},
		productUnit: {
			type: null
		},
		menus: {
			type: null
		}
	},
	computed: {},
	data() {
		return {
			activeCategory: null,
			expandedCategory: null,
			activeProduct: null,
			isFirstActive: false,
			scrollContainer: null,
			isSearchExpanded: false,
		};
	},
	setup() {
		const { t } = useI18n(); // Extract the translation function `t` and the reactive `locale`

		const translateMenuName = (menuTypeName) => {
			switch (menuTypeName) {
				case "Comida":
					return t('menu.food');
				case "Bebida":
					return t('menu.drinks');
				default:
					return menuTypeName;
			}
		};

		return {
			t,
			translateMenuName,
		};
	},
	mounted() {
		this.initializeScrollListener();
	},
	beforeUnmount() {
		if (this.scrollContainer) {
			this.scrollContainer.removeEventListener('scroll', this.handleScroll);
		}
	},
	methods: {
		initializeScrollListener() {
			let offcanvasBody = document.querySelector('#offcanvas-body-commander');
			// Verifica si el navbar está dentro de un offcanvas
			if (offcanvasBody && offcanvasBody.contains(this.$el)) {
				console.log('Es un offcanvas')
				this.scrollContainer = offcanvasBody;
			} else {
				console.log('Es un body')
				this.scrollContainer = window;  // Caso contrario, usa la ventana
			}
			this.scrollContainer.addEventListener('scroll', this.handleScroll);
		},
		filterProducts(products) {
			return Array.isArray(products) ? products : [];
		},
		filterCategoriesRecursively(category) {
			if (!category) return null;

			const filteredChildren = Array.isArray(category.children)
				? category.children
					.map(this.filterCategoriesRecursively)
					.filter(child => child !== null)
				: [];

			const filteredMenuCategories = Array.isArray(category.menuCategories)
				? category.menuCategories
					.map(this.filterCategoriesRecursively)
					.filter(subCategory => subCategory !== null)
				: [];

			const filteredProducts = this.filterProducts(category.products);

			if (
				filteredChildren.length === 0 &&
				filteredMenuCategories.length === 0 &&
				filteredProducts.length === 0
			) {
				return null;
			}

			return {
				...category,
				children: filteredChildren,
				menuCategories: filteredMenuCategories,
				products: filteredProducts,
			};
		},
		handleScroll() {
			let currentActiveCategory = null;
			const tolerance = 0;
			let scrollY = this.scrollContainer === window ? window.scrollY : this.scrollContainer.scrollTop;

			// Filtra el activeMenu antes de recorrerlo
			const filteredActiveMenu = this.activeMenu
				.map(this.filterCategoriesRecursively)
				.filter(category => category !== null);

			// Función recursiva interna para manejar el scroll de categorías y subcategorías
			const handleCategoryScroll = (category, parentIndex = null) => {
				const categoryElement = document.getElementById(category.id + category.name);
				let isCategoryActive = false;

				if (categoryElement) {
					const bounding = categoryElement.getBoundingClientRect();

					// Verifica si la categoría está actualmente visible
					if (bounding.top <= tolerance && bounding.bottom >= tolerance) {
						currentActiveCategory = category.id + category.name;
						this.expandedCategory = category.id + category.name;
						this.isFirstActive = parentIndex === 0;

						const linkMenu = document.getElementById(category.id + '-' + category.name);
						if (linkMenu) linkMenu.classList.add('hidden-text');

						isCategoryActive = true; // Marca que la categoría está activa
					}
				}

				// Procesa el scroll y estado de cada subcategoría y `children` recursivamente
				const subCategories = [...(category.menuCategories || []), ...(category.children || [])];
				let childIsActive = false;

				subCategories.forEach((subCategory) => {
					if (handleCategoryScroll(subCategory, parentIndex)) {
						// Si un hijo está activo, marcamos `childIsActive` como verdadero
						childIsActive = true;
					}
				});

				// Si un hijo está activo, removemos la clase active del padre
				if (childIsActive) {
					const element = document.getElementById(`link-${category.id}`);
					if (element) {
						element.classList.remove('active');
					}
				}

				// Procesa los productos dentro de la categoría
				const elementId = category.id;
				const element = document.getElementById(`link-${elementId}`);

				if (element) {
					const targetSection = document.getElementById(elementId + '-' + category.name);

					if (targetSection) {
						const rect = targetSection.getBoundingClientRect();
						const sectionTop = rect.top + window.pageYOffset - tolerance;

						if (scrollY >= sectionTop && scrollY < sectionTop + rect.height) {
							this.activeProduct = elementId;
							element.classList.add('active');

							// Asegúrate de que el elemento activo se alinee al inicio del contenedor
							let container = document.querySelector('.scroll-x');
							if (container) {
								let scrollLeft = element.offsetLeft - container.offsetLeft;
								container.scrollTo({
									left: scrollLeft,
									behavior: 'smooth' // Aquí se realiza el desplazamiento suave al hijo
								});
							}

							// Devuelve true indicando que este elemento es activo
							return true;
						} else {
							element.classList.remove('active');
						}
					}
				}

				// Devuelve si este elemento o alguno de sus hijos es activo
				return childIsActive || isCategoryActive;
			};

			// Procesa las categorías filtradas de forma recursiva
			filteredActiveMenu.forEach((menu, index) => {
				handleCategoryScroll(menu, index);
			});

			// Actualiza la categoría activa si ha cambiado
			if (this.activeCategory !== currentActiveCategory) {
				this.activeCategory = currentActiveCategory;
				if (!currentActiveCategory) {
					this.expandedCategory = null;
					this.isFirstActive = false;
				}
			}
		},

		handleSearchToggle(isExpanded) {
			this.isSearchExpanded = isExpanded;
		},
		scrollTo(targetId) {
			const targetSection = document.getElementById(targetId);
			if (targetSection) {
				// Calcula la posición del elemento dentro del contenedor
				const rect = targetSection.getBoundingClientRect();
				const scrollContextTop = this.scrollContainer === window ? window.scrollY : this.scrollContainer.scrollTop;
				const absoluteTopPosition = rect.top + scrollContextTop;

				// Considera el offset de una barra de navegación, si existe
				const navbar = document.querySelector('.navbar');
				const navbarOffset = navbar ? -10 : -10;

				// Calcula la posición final del scroll, asegurando que el elemento esté alineado al inicio
				const finalScrollPosition = absoluteTopPosition - navbarOffset;

				if (this.scrollContainer === window) {
					window.scrollTo({
						top: finalScrollPosition,
						behavior: 'smooth'
					});
				} else {
					this.scrollContainer.scrollTo({
						top: finalScrollPosition,
						behavior: 'smooth',
					});
				}
			}
		},
		isActiveCategory(category) {
			return this.activeProduct === category.id + '-' + category.name;
		},
		getIconClass(type) {
			return type === 'Comida' ? 'fas fa-utensils' : 'fas fa-wine-glass'
		},
	}
};
</script>
