<template src="./copilotOrdersContainer.html"></template>

<script>

import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import {mapGetters} from "vuex";
import TableIcon from "@/core/icons/TableIcon";
import { useI18n } from 'vue-i18n';

export default {
	name: 'CopilotOrdersContainer',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		cartSessionRepository: 'cartSessionRepository',
		dateFormatterService: 'dateFormatterService',
	},
	emits: [],
	props: {
		reservations: Array,
	},
	data() {
		return {
			buttonTextOrder: 'Comanda anotada en TPV',
			previousFirstOrder: null,
			isLoadingOrder:false,
			elapsedTime: '',
			isLoadingOrderKitchen: false,
		};
	},
	computed: {
		...mapGetters('copilot',['pendingOrders', 'isKitchenSelectedRole', 'selectedRole', 'isOnlyRoom']),
		sectionTitle(){
			if(this.isKitchenSelectedRole) return this.t('copilot.cocinarPedido');
			return this.notificationType === 'readyOrder' ? this.t('copilot.pedidoListo') : this.t('copilot.nuevaComanda')
		},
		notificationType() {
			if (!this.isLoadingOrder && this.pendingOrders && this.pendingOrders[0]) {
				const cartSessionStatus = this.pendingOrders[0].cartSessionStatus;

				return cartSessionStatus !== 3 ? 'newOrder' : 'readyOrder';
			}
			return null;
		},
		buttonTextOrderLoaded(){
			return this.notificationType === 'readyOrder'
				? 'Pedido servido'
				: (this.isKitchenSelectedRole ? this.t('copilot.pedidoListoParaServir') : this.t('copilot.comandaAnotadaTPV'));
		},
		buttonTextOrderLoading(){
			return this.isKitchenSelectedRole
				? `Enviando pedido <i class="fas fa-spinner fa-spin"></i>`
				: `Anotando comanda <i class="fas fa-spinner fa-spin"></i>`;
		},

		groupedOrders() {
			console.log('pendingOrders', this.pendingOrders);

			if (!this.pendingOrders || !this.pendingOrders[0] || typeof this.pendingOrders[0].items !== 'object') {
				return {};
			}

			const items = this.pendingOrders[0].items;
			const grouped = {};

			for (const key in items) {
				const order = items[key];
				if (order.categoryName) {
					if (!grouped[order.categoryName]) {
						grouped[order.categoryName] = [];
					}
					grouped[order.categoryName].push(order);
				}
			}

			return grouped;
		}
	},
	watch: {
		isKitchenSelectedRole(){
			this.buttonTextOrder = this.buttonTextOrderLoaded;
			// this.buttonTextOrder = this.notificationType === 'readyOrder' ? 'Pedido servido' : this.buttonTextOrder;
		},
		pendingOrders(){
			this.buttonTextOrder = this.buttonTextOrderLoaded;
			// this.buttonTextOrder = this.notificationType === 'readyOrder' ? 'Pedido servido' : this.buttonTextOrder;
		},
	},
	components: {
		'orders-product-card': OrdersProductCard,
		'table-icon': TableIcon
	},
	setup() {
		const { t } = useI18n();
		return { t };
	},
	beforeMount() {
		console.log('Montando copilotOrdersContainer');
	},
	async mounted() {
		this.buttonTextOrder = this.buttonTextOrderLoaded;
		// this.buttonTextOrder = this.notificationType === 'readyOrder' ? 'Pedido servido' : this.buttonTextOrder;
		document.addEventListener('buttonTextOrderUpdated', () => {
			this.buttonTextOrder = this.isKitchenSelectedRole ? this.t('copilot.pedidoListoParaServir') : this.t('copilot.comandaAnotadaTPV');
		});

		this.updateElapsedTime();

		setInterval(() => {
			this.updateElapsedTime();
		}, 1000);

		console.log({Pendinng: this.pendingOrders[0]})
	},
	methods: {

		async handleProcessOrder(cartSessionOrderId) {
			// this.isLoadingNewOrder = true;
			console.log(this.pendingOrders[0])
			// this.buttonTextOrder = this.isKitchenSelectedRole ? `Enviando pedido <i class="fas fa-spinner fa-spin"></i>` : `Anotando comanda <i class="fas fa-spinner fa-spin"></i>`
			this.buttonTextOrder = this.buttonTextOrderLoading;
			const resultProcessOrder = await this.cartSessionRepository.processOrder(cartSessionOrderId, this.selectedRole);
			this.isLoadingOrder = true;

			if (resultProcessOrder.result === 0) {
				// this.isLoadingNewOrder = false;
				setTimeout(() => {
					this.isLoadingOrder = false;
				}, 1000);
			}
		},

		updateElapsedTime() {
			if (!this.pendingOrders.length || !this.pendingOrders[0].createdDateTime) {
				return;
			}
			this.elapsedTime = this.dateFormatterService.formattedTimeChronometer(this.pendingOrders[0].createdDateTime)
		}

	},
}
</script>
