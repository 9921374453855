<template>
		<a
			:id="'link-' + category.id"
			:class="{ 'active': isActiveCategory(category) }"
			class="link-item"
			style="scroll-snap-align: start;flex-shrink: 0;"
			@click.prevent="scrollTo(category.id + '-' + category.name)"
			:data-bs-section="category.id + '-' + category.name"
		>
			{{ translatedMenuCategoryName }}
		</a>
		<template v-if="category.children && category.children.length">
			<NavbarScrollCategoriesItem
				v-for="child in category.children"
				:key="child.id"
				:category="child"
				:scroll-to="scrollTo"
				:is-active-category="isActiveCategory"
			/>
		</template>
</template>

<script>
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {getTranslationKey} from "@/i18n";
import {capitalize} from "@/utils";

export default {
	name: 'NavbarScrollCategoriesItem',
	props: {
		category: {
			type: Object,
			required: true,
		},
		scrollTo: {
			type: Function,
			required: true,
		},
		isActiveCategory: {
			type: Function,
			required: true,
		},
	},
	setup(props) {
		const { t } = useI18n(); // Extract the translation function `t` and the reactive `locale`

		const translatedMenuCategoryName = computed(() => {
			const translationKey = getTranslationKey('menuCategory', props.category, 'name');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? capitalize(translation) : props.category.name;
		});

		return {
			t,
			translatedMenuCategoryName
		};
	},
};
</script>

<style scoped>
.children-container {
	margin-left: 20px; /* Margen adicional para mostrar la jerarquía de los hijos */
}
</style>
